import React from "react";
import Box from "@mui/material/Box";
import SigninForm from "./components/signinForm";

const Signin = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: theme.spacing(4),
        minHeight: "inherit",
      })}
    >
      <SigninForm />
    </Box>
  );
};

export default Signin;
