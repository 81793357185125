import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { Navigate } from "react-router-dom";
import useAuth from "../../providers/authProvider";
import inboxImage from "../../assets/images/inbox_open.svg";
import { toast } from "react-toastify";

const Image = styled("img")(() => ({
  width: "100%",
  height: "auto",
  display: "flex",
  maxWidth: "300px",
}));

const VerifyEmail = () => {
  const { user, startTimer, setStartTimer, sendEmailVerification } = useAuth();
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(60);

  useEffect(() => {
    let interval;

    if (startTimer && time !== 0) {
      interval = setTimeout(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setStartTimer(false);
      setTime(60);
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [startTimer, time, setStartTimer]);

  const resendEmail = async () => {
    setLoading(true);

    const res = await sendEmailVerification(user);

    if (res.status === "success") {
      toast.success("Email is sent successfully");
      setStartTimer(true);
    } else {
      toast.error(res.message);
    }

    setLoading(false);
  };

  if (!user) return <Navigate to="/" />;

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Image src={inboxImage} alt="" />
      <Typography
        mt={4}
        variant="h4"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        Verify your email address
      </Typography>

      <Typography>
        You will need to verify your email to complete registration
      </Typography>

      <Typography
        mt={2}
        sx={{
          maxWidth: "700px",
        }}
      >
        An email has been sent to {user?.email} with the link to verify your
        account. If you have not recieved the email after a few minutes, please
        check your spam folder.
      </Typography>

      <Box mt={3}>
        <LoadingButton
          disabled={startTimer && time !== 0}
          variant="contained"
          loading={loading}
          onClick={resendEmail}
        >
          {startTimer && time !== 0 ? `Resend in ${time}s` : "Resend"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
