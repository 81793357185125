import React from "react";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import useState from "../../../../../hooks/useState";

const SuggestNewTime = ({ onCancel, onAccept, isLoading }) => {
  const [state, setState] = useState({
    newTime: new Date(),
  });
  return (
    <>
      <DialogTitle>Suggest new time</DialogTitle>
      <DialogContent>
        <StaticDateTimePicker
          inputFormat="dd/MM/yyyy hh:mm a"
          disablePast
          value={state.newTime}
          onChange={(newValue) => {
            setState({
              newTime: newValue,
            });
          }}
          renderInput={(props) => <TextField {...props} />}
          displayStaticWrapperAs="mobile"
          components={{
            ActionBar: () => {
              return (
                <>
                  <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <LoadingButton
                      onClick={() => {
                        onAccept(state.newTime);
                      }}
                      loading={isLoading}
                    >
                      Ok
                    </LoadingButton>
                  </DialogActions>
                </>
              );
            },
          }}
        />
      </DialogContent>
    </>
  );
};

export default SuggestNewTime;
