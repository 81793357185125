import React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "../../../../components/container";
import studyingImage from "../../../../assets/images/studying.jpg";
import workingImage from "../../../../assets/images/working.jpg";
import livingImage from "../../../../assets/images/living.jpg";

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
}));

const BodyText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontStyle: "italic",
}));

const Image = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  display: "flex",
}));

const Services = () => {
  return (
    <>
      <Box
        component="section"
        sx={(theme) => ({
          padding: `${theme.spacing(6)} ${theme.spacing(0)}`,
          [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing(10)} ${theme.spacing(0)}`,
          },
        })}
      >
        <Container>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <Title variant="h4" mb={2}>
                Want to move to study at a specific university abroad?
              </Title>

              <BodyText variant="h5">
                Speak to students who have already made that move!
              </BodyText>
            </Grid>

            <Grid item xs={12} md={5}>
              <Image src={studyingImage} />
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        component="section"
        sx={(theme) => ({
          padding: `${theme.spacing(6)} ${theme.spacing(0)}`,
          backgroundColor: "#f7f7f7",
          [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing(10)} ${theme.spacing(0)}`,
          },
        })}
      >
        <Container>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={(theme) => ({
                order: 2,
                [theme.breakpoints.up("md")]: {
                  order: 0,
                },
              })}
            >
              <Image src={workingImage} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Title variant="h4" mb={2}>
                Thinking of moving to another country or city for work?
              </Title>

              <BodyText variant="h5">
                Speak to professionals currently working in that city!
              </BodyText>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box
        component="section"
        sx={(theme) => ({
          padding: `${theme.spacing(6)} ${theme.spacing(0)}`,
          [theme.breakpoints.up("md")]: {
            padding: `${theme.spacing(10)} ${theme.spacing(0)}`,
          },
        })}
      >
        <Container>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} md={6}>
              <Title variant="h4" mb={2}>
                Want to learn more about the lifestyle and general living in a
                new city?
              </Title>

              <BodyText variant="h5">
                Speak to people living in that city with similar circumstances
                to you!
              </BodyText>
            </Grid>

            <Grid item xs={12} md={5}>
              <Image src={livingImage} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Services;
