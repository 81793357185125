import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Centered from "../pageCentered/inddx";

const PageLoader = () => {
  return (
    <Centered>
      <CircularProgress size={20} />
    </Centered>
  );
};

export default PageLoader;
