import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Input from "../input";

const MultiSelect = ({
  id,
  size = "small",
  fullWidth = true,
  data = [],
  handleDelete,
  handleAdd,
  handleBlur,
  error,
  helperText = "",
  name,
}) => {
  const [tag, setTag] = useState("");

  const tagsList = (
    <>
      <Input
        id={id}
        fullWidth={fullWidth}
        size={size}
        value={tag}
        onChange={(e) => setTag(e.target.value)}
        onKeyDown={(e) => {
          if (e.keyCode === 13 || e.code === "Enter") {
            e.preventDefault();

            if (e.target.value?.length <= 0) return;

            const index = data?.findIndex((item) => item === tag);

            if (index === -1) {
              setTag("");
              handleAdd(tag);
            }
          }
        }}
        error={error}
        helperText={helperText}
        onBlur={handleBlur}
        name={name}
      />
      <Box
        display="flex"
        sx={{
          flexWrap: "wrap",
        }}
      >
        {data.map((item) => (
          <Chip
            sx={(theme) => ({
              margin: theme.spacing(0.5),
            })}
            label={item}
            key={item}
            onDelete={() => handleDelete(item)}
            color="primary"
            size="small"
          />
        ))}
      </Box>
    </>
  );

  return <>{tagsList}</>;
};

export default MultiSelect;
