import React from "react";
import Typography from "@mui/material/Typography";

const Error = ({ children }) => {
  return (
    <Typography
      variant="body1"
      sx={(theme) => ({
        color: theme.palette.error.main,
      })}
    >
      {children}
    </Typography>
  );
};

export default Error;
