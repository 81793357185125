import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import useAuth from "../../../providers/authProvider";
import ItemValue from "./itemValue";
import ItemLabel from "./itemLabel";
import { formatDate, getAge } from "../../../utils";
import BookSessionModal from "../../../components/bookSessionModal";
import { formatCurrency } from "../../../utils";

const InputGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  marginBottom: theme.spacing(2),
  display: "inline-block",
  position: "relative",

  "::after": {
    content: "''",
    display: "block",
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.grey[700],
  },
}));

const Profile = ({ guru }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));
  const { user, profile } = useAuth();
  const [openBookSessionDialog, setOpenBookSessionDialog] = useState(false);

  return (
    <>
      {user && profile && profile.id === guru.id && (
        <Box
          sx={{
            position: "fixed",
            bottom: 50,
            right: 50,
          }}
        >
          <Fab component={Link} to="/update-profile">
            <EditIcon />
          </Fab>
        </Box>
      )}
      <Avatar
        sx={(theme) => ({
          width: theme.spacing(12),
          height: theme.spacing(12),
          backgroundColor: theme.palette.grey[700],
        })}
        src={guru.avatar}
      />

      <Box>
        <Grid container spacing={isTablet ? 2 : 0}>
          {/* Left Side */}
          <Grid item xs={12} md={6} lg={5}>
            <Box mt={6}>
              <SectionTitle variant="h5">Personal Details</SectionTitle>
              <InputGrid container alignItems="center">
                <ItemLabel>First Name: </ItemLabel>
                <ItemValue>{guru.firstName}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Surname: </ItemLabel>
                <ItemValue>{guru.surname}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Age: </ItemLabel>
                {guru.dob && (
                  <ItemValue>{getAge(guru.dob?.toDate())}</ItemValue>
                )}
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Sex: </ItemLabel>
                <ItemValue>{guru.sex}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Location: </ItemLabel>
                <ItemValue>{guru.location}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Marital Status: </ItemLabel>
                <ItemValue>{guru.maritalStatus}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Kids: </ItemLabel>
                <ItemValue>{guru.kids}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Languages: </ItemLabel>
                <ItemValue>{guru.languages?.join(", ")}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Ethnicity: </ItemLabel>
                <ItemValue>{guru.ethnicity}</ItemValue>
              </InputGrid>
            </Box>

            <Box mt={6}>
              <SectionTitle variant="h5">Education Details</SectionTitle>

              <InputGrid container alignItems="center">
                <ItemLabel>Highest Qualif. Level: </ItemLabel>
                <ItemValue>{guru.highestQualifLevel}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>University: </ItemLabel>
                <ItemValue>{guru.university}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Degree/Subject: </ItemLabel>
                <ItemValue>{guru.degree}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Graduation Year: </ItemLabel>
                <ItemValue>
                  {guru.graduationYear
                    ? formatDate(guru.graduationYear?.toDate(), "yyyy")
                    : ""}
                </ItemValue>
              </InputGrid>
            </Box>
          </Grid>

          {/* Right Side */}

          <Grid item xs={12} md={6} lg={7}>
            <Box mt={6}>
              <SectionTitle variant="h5">Professional Details</SectionTitle>

              <InputGrid container alignItems="center">
                <ItemLabel>Occupation: </ItemLabel>
                <ItemValue>{guru.occupation}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Industry: </ItemLabel>
                <ItemValue>{guru.industry}</ItemValue>
              </InputGrid>

              {guru.linkedinProfile && (
                <InputGrid container alignItems="center">
                  <ItemLabel>LinkedIn Profile: </ItemLabel>
                  <ItemValue>
                    <a
                      href={guru.linkedinProfile}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {guru.linkedinProfile}
                    </a>
                  </ItemValue>
                </InputGrid>
              )}
            </Box>

            <Box mt={6}>
              <SectionTitle variant="h5">Life Style</SectionTitle>

              <InputGrid container alignItems="center">
                <ItemLabel>Interests: </ItemLabel>
                <ItemValue>{guru.interests?.join(", ")}</ItemValue>
              </InputGrid>

              <InputGrid container alignItems="center">
                <ItemLabel>Sports: </ItemLabel>
                <ItemValue>{guru.sports?.join(", ")}</ItemValue>
              </InputGrid>
            </Box>

            <Box mt={6}>
              <SectionTitle variant="h5">Personal Profile</SectionTitle>
              <InputGrid container alignItems="center">
                <ItemLabel>Insights on: </ItemLabel>
                <ItemValue>{guru.insightson?.join(", ")}</ItemValue>
              </InputGrid>
              <InputGrid container alignItems="center">
                <Typography>{guru.personalProfile}</Typography>
              </InputGrid>
            </Box>
          </Grid>
        </Grid>

        {user && guru && guru.id !== user.uid && (
          <>
            <BookSessionModal
              open={openBookSessionDialog}
              guru={guru}
              onClose={() => {
                setOpenBookSessionDialog(false);
              }}
            />
            {guru.price && (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={(theme) => ({
                  marginTop: theme.spacing(4),
                })}
              >
                <Typography
                  sx={(theme) => ({
                    fontWeight: theme.typography.fontWeightBold,
                    marginRight: theme.spacing(2),
                  })}
                >
                  {formatCurrency(guru.price)} per session
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setOpenBookSessionDialog(true);
                  }}
                >
                  Book Session
                </Button>
              </Stack>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default Profile;
