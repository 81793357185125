import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Link from "../../../../components/link";
import useAuth from "../../../../providers/authProvider";
import { formatDate } from "../../../../utils";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { toast } from "react-toastify";
import useState from "../../../../hooks/useState";

const Session = ({ session, onJoin, isCompletingSession, completeSession }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [state, setState] = useState({
    isLoadingPaymentPage: false,
  });
  const { user } = useAuth();

  let type = "explorer";

  if (user.uid === session.guruId) {
    type = "guru";
  }

  const date = session.sessionTime?.toDate();

  const createChargePage = async () => {
    setState({
      isLoadingPaymentPage: true,
    });
    try {
      const response = await axios.post(
        process.env.REACT_APP_CREATE_PAYMENT_PAGE_URL,
        {
          sessionId: session.id,
        }
      );

      const url = response.data.data;

      setState({
        isLoadingPaymentPage: false,
      });

      window.location.href = url;
    } catch (err) {
      console.log(err);
      setState({
        isLoadingPaymentPage: false,
      });
      toast.error("Something went wrong!");
    }
  };

  return (
    <Stack
      spacing={3}
      sx={(theme) => ({
        padding: theme.spacing(2),
      })}
    >
      <Stack
        spacing={isDesktop ? 3 : 0.5}
        direction={isDesktop ? "row" : "column"}
      >
        <Box
          sx={{
            minWidth: 85,
          }}
        >
          <Typography>{formatDate(date, "d LLL yyyy")}</Typography>
          <Typography>{formatDate(date, "HH:mm")}</Typography>
        </Box>
        <Box sx={{ textAlign: isDesktop ? "center" : "left" }}>
          <Avatar
            src={type === "guru" ? "" : session.guru.avatar}
            variant="square"
            sx={(theme) => ({
              width: theme.spacing(10),
              height: theme.spacing(10),
            })}
          />
          <Typography
            sx={(theme) => ({
              marginTop: theme.spacing(0.5),
            })}
          >
            {type === "guru" ? "Explorer" : "Guru"}
          </Typography>
        </Box>
        <Box>
          {type === "guru" ? (
            <Typography>{session.explorer.name}</Typography>
          ) : (
            <Link to={`/profile/${session.guruId}`}>{session.guru.name}</Link>
          )}
          {type !== "guru" && <Typography>{session.guru.location}</Typography>}

          {type === "guru" && !session.paymentMade && (
            <Typography>Explorer has not made payment yet</Typography>
          )}

          {type === "guru" && session.paymentMade && (
            <Button
              type="button"
              variant="contained"
              mt={1}
              onClick={() => {
                onJoin(session, type);
              }}
            >
              Start Session
            </Button>
          )}

          {type === "explorer" && !session.paymentMade && (
            <LoadingButton
              variant="contained"
              mt={1}
              onClick={createChargePage}
              loading={state.isLoadingPaymentPage}
            >
              Make Payment
            </LoadingButton>
          )}

          {type === "explorer" && session.paymentMade && !session.isGuruJoined && (
            <Button type="button" variant="contained" disabled mt={1}>
              Session not started
            </Button>
          )}

          {type === "explorer" && session.paymentMade && session.isGuruJoined && (
            <Button
              type="button"
              variant="contained"
              mt={1}
              onClick={() => {
                onJoin(session, type);
              }}
            >
              Join Session
            </Button>
          )}

          {type === "explorer" && session.paymentMade && session.wasStarted && (
            <LoadingButton
              variant="contained"
              sx={{
                marginTop: 1,
              }}
              loading={isCompletingSession}
              onClick={() => completeSession(session)}
            >
              Complete session
            </LoadingButton>
          )}
        </Box>
      </Stack>
    </Stack>
  );
};

export default Session;
