import React from "react";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { combineName, getAge } from "../../../utils";
import useState from "../../../hooks/useState";
import { toast } from "react-toastify";

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(16),
}));

const Profile = ({ profile, user }) => {
  const [state, setState] = useState({
    statusConnectingAccount: "idle",
    statusLoadingStripeDashboard: "idle",
  });

  const createStripeAccount = async () => {
    if (!profile) return;

    setState({ statusConnectingAccount: "loading" });

    try {
      const response = await axios.post(
        process.env.REACT_APP_CREATE_STRIPE_ACCOUNT_API_URL,
        {
          guruId: profile.id,
        }
      );

      const url = response.data.data;

      window.location.href = url;

      setState({ statusConnectingAccount: "idle" });
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!");
      setState({
        statusConnectingAccount: "idle",
      });
    }
  };

  const createStripeDashboardLink = async () => {
    try {
      setState({
        statusLoadingStripeDashboard: "loading",
      });

      if (profile.accountId && profile.chargesEnabled) {
        const response = await axios.post(
          process.env.REACT_APP_GENERATE_EXPRESS_DASHBOARD_URL,
          {
            accountId: profile.accountId,
          }
        );

        const url = response.data.data;
        window.location.href = url;

        setState({
          statusLoadingStripeDashboard: "idle",
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
      setState({
        statusLoadingStripeDashboard: "idle",
      });
    }
  };

  return (
    <>
      <Stack direction="row">
        <UserAvatar src={profile?.avatar} variant="square" />
        <Box ml={2}>
          <Typography
            variant="h6"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
            })}
          >
            {!profile
              ? user.displayName
              : combineName(profile.firstName, profile.surname)}
          </Typography>
          {!profile ? (
            <>
              <Button
                sx={(theme) => ({
                  marginTop: theme.spacing(1),
                })}
                variant="contained"
                component={Link}
                to="/become-guru"
              >
                Create Guru Profile
              </Button>
            </>
          ) : (
            <>
              <List disablePadding>
                <ListItem disablePadding>{profile.location}</ListItem>
                <ListItem disablePadding>
                  {getAge(profile.dob?.toDate())}, {profile.maritalStatus}
                </ListItem>
                <ListItem disablePadding>{profile.occupation}</ListItem>
              </List>
            </>
          )}
        </Box>
      </Stack>

      {profile && (
        <Box mt={2}>
          <Typography>{profile.personalProfile}</Typography>
          <Stack direction="row" justifyContent="flex-end" mt={2}>
            {!profile.chargesEnabled ? (
              <LoadingButton
                variant="contained"
                loading={state.statusConnectingAccount === "loading"}
                onClick={createStripeAccount}
              >
                Add Payment Info
              </LoadingButton>
            ) : (
              <LoadingButton
                variant="contained"
                loading={state.statusLoadingStripeDashboard === "loading"}
                onClick={createStripeDashboardLink}
              >
                Payment Info
              </LoadingButton>
            )}
            <Button
              component={Link}
              to="/update-profile"
              sx={(theme) => ({
                marginLeft: theme.spacing(2),
                textAlign: "center",
              })}
              variant="contained"
            >
              Update Profile
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default Profile;
