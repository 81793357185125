import { Routes, Route } from "react-router-dom";
import BecomeGuru from "../../pages/becomeGuru";
import Register from "../../pages/register";
import Signin from "../../pages/signin";
import NotFound from "../../pages/404";
import VerifyEmail from "../../pages/verifyEmail";
import Landing from "../../pages/Landing";
import Dashboard from "../../pages/dashboard";
import ProtectedRoute from "./privateRoute";
import NotLoggedIn from "./notLoggedin";
import HaveProfile from "./haveProfiler";
import NotHaveProfile from "./notHaveProfile";
import Profile from "../../pages/profile";
import Browse from "../../pages/browse";
import UpdateGuruProfile from "../../pages/updateGuruProfile";
import Search from "../../pages/search";
import ForgotPassword from "../../pages/forgotPassword";
import Layout1 from "../layouts/layout1";
import History from "../../pages/history";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route element={<Layout1 />}>
        <Route path="/profile/:guruId" element={<Profile />} />
        <Route path="/browse" element={<Browse />} />
        <Route path="/search" element={<Search />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<NotLoggedIn />}>
          <Route path="/register" element={<Register />} />
          <Route path="/signin" element={<Signin />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route element={<HaveProfile />}>
            <Route path="/update-profile" element={<UpdateGuruProfile />} />
          </Route>
          <Route element={<NotHaveProfile />}>
            <Route path="/become-guru" element={<BecomeGuru />} />
          </Route>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/history" element={<History />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
