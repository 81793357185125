import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Link from "../../../components/link";
import Input from "../../../components/input";
import useAuth from "../../../providers/authProvider";
import PasswordField from "../../../components/passwordField";
import { combineName } from "../../../utils";

const CustomPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  padding: theme.spacing(4),
}));

const RegisterForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirect");

  const initialValues = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required!")
      .min(2, "Too short!")
      .max(50, "Too long!"),

    surname: Yup.string()
      .required("Surname is required!")
      .min(2, "Too short!")
      .max(50, "Too long!"),

    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required!"),

    password: Yup.string()
      .min(6, "Password must be at least 6 charcaters long!")
      .required("Password is required"),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match!")
      .required("Confirm your password"),
  });

  const onSubmit = async (
    { email, password, name, surname },
    { setSubmitting }
  ) => {
    const res = await auth.signup(email, password, combineName(name, surname));

    if (res.status === "success") {
      toast.success("User is registered successfully");
      await auth.sendEmailVerification(res.user);
      auth.setStartTimer(true);
      setSubmitting(false);
      navigate("/verify-email");
    } else {
      toast.error(res.message);
      setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <CustomPaper>
      <Typography
        variant="h4"
        component="p"
        align="center"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightMedium,
        })}
      >
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Name"
          type="text"
          fullWidth
          margin="normal"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.name && touched.name}
          helperText={errors.name && touched.name ? errors.name : ""}
        />

        <Input
          placeholder="Surname"
          type="text"
          fullWidth
          margin="normal"
          name="surname"
          value={values.surname}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.surname && touched.surname}
          helperText={errors.surname && touched.surname ? errors.surname : ""}
        />

        <Input
          placeholder="Email"
          type="text"
          fullWidth
          margin="normal"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
          helperText={errors.email && touched.email ? errors.email : ""}
        />

        <PasswordField
          placeholder="Password"
          fullWidth
          margin="normal"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password && touched.password}
          helperText={
            errors.password && touched.password ? errors.password : ""
          }
        />

        <PasswordField
          placeholder="Confirm Password"
          fullWidth
          margin="normal"
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.confirmPassword && touched.confirmPassword}
          helperText={
            errors.confirmPassword && touched.confirmPassword
              ? errors.confirmPassword
              : ""
          }
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            marginTop: theme.spacing(3),
          })}
        >
          <FormControlLabel label="Remember me" control={<Checkbox />} />

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting}
          >
            Register
          </LoadingButton>
        </Stack>
        <Typography variant="body2" mt={3} align="right">
          Already have an account?{" "}
          <Link
            to={redirectUrl ? `/signin?redirect=${redirectUrl}` : "/signin"}
          >
            Sign in
          </Link>
        </Typography>
      </form>
    </CustomPaper>
  );
};

export default RegisterForm;
