import React from "react";
import Grid from "@mui/material/Grid";

const GridItemInput = ({ children }) => {
  return (
    <Grid item xs={12} sm={8} md={7} lg={8}>
      {children}
    </Grid>
  );
};

export default GridItemInput;
