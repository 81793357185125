import React from "react";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import useState from "../../hooks/useState";
import Select from "../../components/select";
import insightsonOptions from "../../components/guruForm/data/insgihtsonOptions";
import Searchbar from "../../components/searchbar";

const Label = styled("label")(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  marginBottom: 8,
  display: "block",
}));

const Search = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    query: "",
    sex: "",
    kids: "",
    maritalStatus: "",
    insightson: [],
    qualification: "",
  });

  const { query, sex, kids, maritalStatus, insightson, qualification } = state;

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  const getSearchUrl = () => {
    const urlSearchParams = new URLSearchParams();

    if (query && query.trim().length !== 0) {
      urlSearchParams.append("query", query);
    }

    if (sex && sex !== "other") {
      urlSearchParams.append("sex", sex);
    }

    if (kids && kids !== "Any") {
      urlSearchParams.append("kids", kids);
    }

    if (maritalStatus) {
      urlSearchParams.append("maritalStatus", maritalStatus);
    }

    if (qualification && qualification !== "Any") {
      urlSearchParams.append("qualification", qualification);
    }

    if (insightson && insightson.length > 0) {
      insightson.forEach((op) => {
        urlSearchParams.append("insightsOn", op);
      });
    }

    const queryStr = urlSearchParams.toString();

    const url = `/browse?${queryStr}`;

    navigate(url);
  };

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h5"
        mb={3}
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightMedium,
        })}
      >
        Advanced Search
      </Typography>

      <Searchbar
        value={query}
        name="query"
        onChange={handleChange}
        clear={() => setState({ query: "" })}
        placeholder="Search for location, university, occupation, languages, ..."
      />

      <Box mt={3}>
        {/* Sex Filter */}
        <Grid container>
          <Grid item xs={12}>
            <Label>Sex: </Label>
          </Grid>

          <Grid item xs={12}>
            <RadioGroup value={sex} name="sex" row onChange={handleChange}>
              <FormControlLabel value="Male" control={<Radio />} label="Male" />

              <FormControlLabel
                value="Female"
                control={<Radio />}
                label="Female"
              />

              <FormControlLabel
                value="other"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        {/* Kids filter */}
        <Grid container mt={3}>
          <Grid item xs={12}>
            <Label>Kids: </Label>
          </Grid>
          <Grid item xs={12}>
            <Select
              options={["Yes", "No", "Any"]}
              value={kids}
              name="kids"
              onChange={(_, value) => {
                setState({
                  kids: value,
                });
              }}
              disableClearable
            />
          </Grid>
        </Grid>

        {/* Marital Status filter */}
        <Grid container mt={3}>
          <Grid item xs={12}>
            <Label>Marital Status: </Label>
          </Grid>

          <Grid item xs={12}>
            <RadioGroup
              value={maritalStatus}
              name="maritalStatus"
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Single"
                control={<Radio />}
                label="Single"
              />

              <FormControlLabel
                value="Married"
                control={<Radio />}
                label="Married"
              />

              <FormControlLabel
                value="Divorced"
                control={<Radio />}
                label="Divorced"
              />

              <FormControlLabel
                value="Widowed"
                control={<Radio />}
                label="Widowed"
              />
            </RadioGroup>
          </Grid>
        </Grid>

        {/* Qualification filter */}
        <Grid container mt={3}>
          <Grid item xs={12}>
            <Label>Qualification: </Label>
          </Grid>
          <Grid item xs={12}>
            <Select
              options={["High School", "Bachelor", "Masters", "PhD", "Any"]}
              value={qualification}
              name="qualification"
              onChange={(_, value) => {
                setState({
                  qualification: value,
                });
              }}
              disableClearable
            />
          </Grid>
        </Grid>

        {/* Insights on filter */}
        <Grid container alignItems="center" mt={3}>
          <Grid item xs={12}>
            <Label>Insights on: </Label>
          </Grid>
          <Grid item xs={12}>
            <Select
              value={insightson}
              options={insightsonOptions}
              multiple
              size="small"
              onChange={(_, value) => {
                setState({
                  insightson: value,
                });
              }}
            />
          </Grid>
        </Grid>

        <Box mt={8} pb={4} textAlign="right">
          <Button variant="contained" onClick={getSearchUrl}>
            Search
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Search;
