import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import JitsiPlayer from "../jitsi";
import useState from "../../../hooks/useState";
import Session from "./session";
import useAuth from "../../../providers/authProvider";
import { doc, updateDoc } from "firebase/firestore";
import db from "../../../firebase/config";
import { toast } from "react-toastify";
import { sessionStatuses } from "../../../utils";
import Link from "../../../components/link";

const JistiContainer = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100vh",
  backgroundColor: theme.palette.primary.light,
  zIndex: 9998,
}));

const Sessions = ({ sessions, pagesCount, handlePageChange }) => {
  const { user } = useAuth();

  const [state, setState] = useState({
    selectedSession: null,
    isCompletingSession: false,
  });

  const joinSession = async (session, type) => {
    try {
      const updates = {};

      if (type === "guru") {
        updates.isGuruJoined = true;
      } else {
        updates.isExplorerJoined = true;
      }

      const docRef = doc(db, "sessions", session.id);
      await updateDoc(docRef, updates);

      document.body.style.overflow = "hidden";
      setState({
        selectedSession: session,
      });
    } catch (error) {
      toast.error("Something went wron while starting your session.");
    }
  };

  const leftSession = async () => {
    let type = "explorer";

    if (user.uid === state.selectedSession.guruId) {
      type = "guru";
    }

    try {
      const docRef = doc(db, "sessions", state.selectedSession.id);

      const updates = {};

      if (type === "guru") {
        updates.isGuruJoined = false;
      } else {
        updates.isExplorerJoined = false;
        updates.wasStarted = true;
      }

      await updateDoc(docRef, updates);

      document.body.style.overflow = "auto";
      window.location.reload();
    } catch (error) {
      toast.error("Something went wrong while closing session.");
    }
  };

  const completeSession = async (session) => {
    try {
      setState({
        isCompletingSession: true,
      });

      const docRef = doc(db, "sessions", session.id);
      await updateDoc(docRef, {
        status: sessionStatuses.completed,
      });

      setState({
        isCompletingSession: false,
      });
    } catch (err) {
      console.log(err);
      toast.error(
        "Something went wrong while completing the session please try again later."
      );
      setState({
        isCompletingSession: false,
      });
    }
  };

  return (
    <>
      {state.selectedSession && (
        <JistiContainer>
          <JitsiPlayer
            onReadyToClose={leftSession}
            roomName={`${state.selectedSession.explorerId}-${state.selectedSession.guruId}`}
            userInfo={{
              displayName:
                user.uid === state.selectedSession.guruId
                  ? state.selectedSession.guru.name
                  : state.selectedSession.explorer.name,
            }}
            configOverwrite={{
              enableWelcomePage: false,
            }}
            getIFrameRef={(node) => {
              node.style.width = "100%";
              node.style.height = "100%";
              node.style.position = "relative";
              node.style.zIndex = 9999;
            }}
            interfaceConfigOverwrite={{
              APP_NAME: "Nossela",
              SHOW_PROMOTIONAL_CLOSE_PAGE: false,
              SHOW_CHROME_EXTENSION_BANNER: false,
              SHOW_JITSI_WATERMARK: false,
            }}
            onApiReady={(api) => {
              api.executeCommand(
                "avatarUrl",
                user.uid === state.selectedSession.guruId
                  ? state.selectedSession.guru.avatar
                  : ""
              );
              api.executeCommand("subject", "Nossela");
            }}
          />
        </JistiContainer>
      )}
      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.palette.grey[300]}`,
          height: "100%",
        })}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            padding: theme.spacing(2),
          })}
        >
          <Typography
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
            })}
          >
            My Sessions
          </Typography>
          <Link to="/history">History</Link>
        </Stack>

        {sessions.map((session) => (
          <Session
            key={session.id}
            session={session}
            onJoin={joinSession}
            isCompletingSession={state.isCompletingSession}
            completeSession={completeSession}
          />
        ))}

        {sessions && sessions.length <= 0 && (
          <Typography
            sx={(theme) => ({
              padding: theme.spacing(2),
            })}
          >
            Your sessions will appear here
          </Typography>
        )}
        {pagesCount > 1 && (
          <Box
            sx={(theme) => ({
              ul: {
                justifyContent: "flex-end",
                padding: theme.spacing(3),
              },
            })}
          >
            <Pagination
              count={pagesCount}
              onChange={(e, page) => handlePageChange(page)}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default Sessions;
