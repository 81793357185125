import React from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import Input from "../input";

const DatePicker = ({
  setFieldTouched = () => {},
  field = "",
  error,
  touched,
  ...props
}) => {
  return (
    <MuiDatePicker
      {...props}
      renderInput={(params) => {
        return (
          <Input
            {...params}
            onBlur={() => setFieldTouched(field, true, false)}
            helperText={error && touched ? error : ""}
            size="small"
            fullWidth
          />
        );
      }}
    />
  );
};

export default DatePicker;
