import React from "react";
import Header from "./components/header";
import Services from "./components/servicesGrid";
import AppBar from "../../components/appBar";
import Footer from "../../components/footer";

const Landing = () => {
  return (
    <>
      <AppBar />
      <Header />
      <Services />
      <Footer />
    </>
  );
};

export default Landing;
