import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/lab/LoadingButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import { updateDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import Link from "../../../../components/link";
import { sessionStatuses } from "../../../../utils";
import db from "../../../../firebase/config";
import useAuth from "../../../../providers/authProvider";
import { formatDate } from "../../../../utils";
import useState from "../../../../hooks/useState";
import SuggestNewTime from "./suggestNewTimeModal";

const Request = ({ req }) => {
  const [state, setState] = useState({
    isCancelingSession: false,
    isAcceptingSession: false,
    isSuggestingNewTime: false,
    isSuggestNewTimeModalOpen: false,
  });

  const { user } = useAuth();

  let type = "explorer";

  if (user.uid === req.guruId) {
    type = "guru";
  }

  const date = req.sessionTime?.toDate();

  const rejectRequest = async (reqId) => {
    try {
      setState({
        isCancelingSession: true,
      });

      const docRef = doc(db, "sessions", reqId);

      await updateDoc(docRef, {
        status: sessionStatuses.rejected,
      });

      toast.success("Session request is cancelled successfully.");

      setState({
        isCancelingSession: false,
      });
    } catch (err) {
      toast.error("Something went wrong");
      setState({
        isCancelingSession: false,
      });
    }
  };

  const acceptRequest = async (reqId) => {
    try {
      setState({
        isAcceptingSession: true,
      });

      const docRef = doc(db, "sessions", reqId);

      await updateDoc(docRef, {
        status: sessionStatuses.accepted,
      });

      toast.success("Session request is accepted successfully.");

      setState({
        isAcceptingSession: false,
      });
    } catch (err) {
      toast.error("Something went wrong");
      setState({
        isAcceptingSession: false,
      });
    }
  };

  return (
    <>
      <Stack
        spacing={3}
        sx={(theme) => ({
          padding: theme.spacing(2),
        })}
      >
        <Stack spacing={3} direction="row">
          <Box
            sx={{
              minWidth: "85px",
            }}
          >
            <Typography>{formatDate(date, "d LLL yyyy")}</Typography>
            <Typography>{formatDate(date, "HH:mm")}</Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Avatar
              src={type === "guru" ? "" : req.guru.avatar}
              variant="square"
              sx={(theme) => ({
                width: theme.spacing(10),
                height: theme.spacing(10),
              })}
            />
            <Typography
              sx={(theme) => ({
                marginTop: theme.spacing(0.5),
              })}
            >
              {type === "guru" ? "Explorer" : "Guru"}
            </Typography>
          </Box>
          <Box
            sx={{
              minWidth: "85px",
            }}
          >
            {type === "guru" ? (
              <Typography>{req.explorer.name}</Typography>
            ) : (
              <Link to={`/profile/${req.guruId}`}>{req.guru.name}</Link>
            )}
            {type === "guru" ? (
              <Typography>Status: {req.status}</Typography>
            ) : (
              <Typography>{req.guru.location}</Typography>
            )}
          </Box>

          <Stack flexGrow={1} flexShrink={2}>
            <Typography>{req.description}</Typography>

            {type === "guru" && req.isMadeRequestForNewSessionTime && (
              <Typography>
                You have proposed a new time:
                {formatDate(date, "d LLL yyyy HH:mm")}
              </Typography>
            )}

            {type === "explorer" && req.isMadeRequestForNewSessionTime && (
              <Typography>
                Guru has made request for new time:
                {formatDate(date, "d LLL yyyy HH:mm")}
              </Typography>
            )}

            <ButtonGroup
              sx={(theme) => ({
                marginTop: theme.spacing(3),
                alignSelf: "flex-end",
              })}
            >
              <Button
                onClick={() => {
                  rejectRequest(req.id);
                }}
                loading={state.isCancelingSession}
                disabled={state.isAcceptingSession}
              >
                REJECT
              </Button>

              {type === "explorer" && req.isMadeRequestForNewSessionTime && (
                <>
                  <Button
                    disabled={state.isCancelingSession}
                    loading={state.isAcceptingSession}
                    onClick={() => acceptRequest(req.id)}
                  >
                    Accept
                  </Button>
                </>
              )}

              {type === "guru" && !req.isMadeRequestForNewSessionTime && (
                <Button
                  onClick={() => {
                    setState({ isSuggestNewTimeModalOpen: true });
                  }}
                >
                  Suggest new time
                </Button>
              )}

              {type === "guru" && !req.isMadeRequestForNewSessionTime && (
                <>
                  <Button
                    disabled={state.isCancelingSession}
                    loading={state.isAcceptingSession}
                    onClick={() => acceptRequest(req.id)}
                  >
                    Accept
                  </Button>
                </>
              )}
            </ButtonGroup>
          </Stack>
        </Stack>
      </Stack>

      <Dialog
        open={state.isSuggestNewTimeModalOpen}
        onClose={() => {
          setState({
            isSuggestNewTimeModalOpen: false,
          });
        }}
        fullWidth
        maxWidth="sm"
      >
        <SuggestNewTime
          onCancel={() => {
            setState({ isSuggestNewTimeModalOpen: false });
          }}
          isLoading={state.isSuggestingNewTime}
          onAccept={async (newTime) => {
            setState({
              isSuggestingNewTime: true,
            });

            try {
              const docRef = doc(db, "sessions", req.id);

              await updateDoc(docRef, {
                isMadeRequestForNewSessionTime: true,
                sessionTime: newTime,
              });

              toast.success(
                `Your request has been sent to ${req.explorer.name}`
              );

              setState({
                isSuggestingNewTime: false,
                isSuggestNewTimeModalOpen: false,
              });
            } catch (err) {
              console.log(err);
              toast.error(
                "Something went wrong while sendig your request, please try again later."
              );
              setState({
                isSuggestingNewTime: false,
              });
            }
          }}
        />
      </Dialog>
    </>
  );
};

export default Request;
