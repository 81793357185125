import React from "react";
import Dialog from "@mui/material/Dialog";
import { collection, addDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { combineName, sessionStatuses } from "../../utils";
import useState from "../../hooks/useState";
import BookSession from "./bookSession";
import db from "../../firebase/config";
import useAuth from "../../providers/authProvider";

const BookSessionModal = ({ open, onClose, guru }) => {
  const { user, profile } = useAuth();
  const [state, setState] = useState({
    isCreatingSession: false,
  });

  const handleBookSession = async ({ sessionTime, description }) => {
    try {
      setState({
        isCreatingSession: true,
      });

      const explorerId = user.uid;
      const guruId = guru.id;

      await addDoc(collection(db, "sessions"), {
        explorerId,
        guruId,
        explorer: {
          name: profile
            ? combineName(profile.firstName, profile.surname)
            : user.displayName,
          avatar: profile ? profile.avatar : "",
          location: profile ? profile.location : "",
          email: profile ? profile.email : user.email,
        },
        guru: {
          name: combineName(guru.firstName, guru.surname),
          avatar: guru.avatar,
          location: guru.location,
          email: guru.email,
          price: guru.price,
        },
        status: sessionStatuses.pending,
        sessionTime: sessionTime,
        description,
        createdAt: new Date(),
      });

      toast.success(
        `Your session with ${combineName(
          guru.firstName,
          guru.surname
        )} is scheduled successfully.`
      );

      setState({
        isCreatingSession: false,
      });
      onClose();
    } catch (err) {
      toast.error("Something went wrong while creating your session");
      setState({
        isCreatingSession: false,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <BookSession
        guru={guru}
        onCancel={onClose}
        onAccept={handleBookSession}
        isLoading={state.isCreatingSession}
      />
    </Dialog>
  );
};

export default BookSessionModal;
