import React, { useEffect, useCallback } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import Link from "../../components/link";
import useState from "../../hooks/useState";
import Error from "../../components/error";
import Guru from "./components/guru";
import axios from "axios";
import { Pagination } from "@mui/material";

const Center = styled(Box)(() => ({
  width: "100%",
  minHeight: "calc(100vh - 250px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

const Browse = () => {
  const [state, setState] = useState({
    status: "idle",
    gurus: [],
    pagesCount: 1,
  });

  const { status, gurus, pagesCount } = state;

  const [params, setParams] = useSearchParams();

  const compileGurus = useCallback((gurus) => {
    const data = [];

    gurus.forEach((guru) => {
      data.push({ id: guru.objectID, ...guru });
    });

    return data;
  }, []);

  const handlePageChange = (_, value) => {
    params.set("page", `${value}`);
    setParams(params);
  };

  const currentPage = Number(params.get("page") ?? 1);

  const getContraints = useCallback(() => {
    const query = params.get("query") ?? "";
    const sex = params.get("sex");
    const kids = params.get("kids");
    const maritalStatus = params.get("maritalStatus");
    const insightsOn = params.getAll("insightsOn");
    const qualification = params.get("qualification");
    const tag = params.get("tag");

    const constraints = [];

    if (sex) {
      constraints.push(`sex:${sex}`);
    }

    if (kids) {
      constraints.push(`kids:${kids}`);
    }

    if (maritalStatus) {
      constraints.push(`maritalStatus:${maritalStatus}`);
    }

    if (qualification) {
      constraints.push(`highestQualifLevel:'${qualification}'`);
    }

    if (insightsOn && insightsOn.length > 0) {
      const insightsOnConstraints = [];

      insightsOn.forEach((item) => {
        insightsOnConstraints.push(`insightson: '${item}'`);
      });

      constraints.push(insightsOnConstraints.join(" AND "));
    }

    if (tag) {
      constraints.push(`tags:${tag}`);
    }

    constraints.push(`chargesEnabled:${true}`);

    const filters = constraints.join(" AND ");

    return {
      query,
      filters,
    };
  }, [params]);

  useEffect(() => {
    const fetchGurus = async () => {
      setState({
        status: "loading",
      });
      try {
        const constraints = getContraints();

        var body = JSON.stringify({
          query: constraints.query,
          filters: constraints.filters,
          attributesToHighlight: [],
          page: currentPage - 1,
        });

        var config = {
          method: "post",
          url: `https://${process.env.REACT_APP_ALGOLIA_APP_ID}-dsn.algolia.net/1/indexes/Nossela/query`,
          headers: {
            "X-Algolia-API-Key": process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
            "X-Algolia-Application-Id": process.env.REACT_APP_ALGOLIA_APP_ID,
            "Content-Type": "application/json",
          },
          data: body,
        };

        const response = await axios(config);

        const data = compileGurus(response.data.hits);

        setState({
          gurus: data,
          pagesCount: response.data.nbPages,
          status: "loaded",
        });
      } catch (err) {
        setState({
          status: "error",
        });
      }
    };

    fetchGurus();
  }, [getContraints, compileGurus, currentPage]);

  let jsx = null;

  if (status === "loading") {
    jsx = (
      <Center>
        <CircularProgress size={20} />
      </Center>
    );
  } else if (status === "error") {
    jsx = (
      <Center>
        <Error>Something went wrong</Error>
      </Center>
    );
  } else if (status === "loaded" && gurus?.length <= 0) {
    jsx = <Typography>0 gurus are found.</Typography>;
  } else if (status === "loaded" && gurus.length > 0) {
    jsx = (
      <>
        {gurus.map((guru, index) => (
          <Box key={guru.id} mt={index > 0 ? 7 : 0}>
            <Guru guru={guru} />
          </Box>
        ))}
        {pagesCount > 1 && (
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Pagination
              count={pagesCount}
              color="primary"
              onChange={handlePageChange}
              page={currentPage}
            />
          </Box>
        )}
      </>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Link to="/search">Advanced Search</Link>
      </Box>
      {jsx}
    </Container>
  );
};

export default Browse;
