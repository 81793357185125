import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import CompletedSessions from "./completedSessions";
import RejectedSessions from "./rejetedSessions";

const History = () => {
  const [value, setValue] = useState("0");

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Completed Sessions" value="0" />
          <Tab label="Rejected Sessions" value="1" />
        </TabList>
      </Box>
      <TabPanel value="0">
        <CompletedSessions />
      </TabPanel>
      <TabPanel value="1">
        <RejectedSessions />
      </TabPanel>
    </TabContext>
  );
};

export default History;
