import React, { useRef } from "react";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";

const FileInput = styled("input")(() => ({
  visibility: "hidden",
  width: 0,
  height: 0,
}));

const FilePicker = ({ onFileChange, value, loading }) => {
  const fileInputRef = useRef(null);

  const openFilePicker = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <FileInput
        type="file"
        select="image/*"
        onChange={onFileChange}
        ref={fileInputRef}
      />
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <>
            {!loading && (
              <IconButton
                disabled={loading}
                onClick={openFilePicker}
                sx={(theme) => ({
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                  ":hover": {
                    background: theme.palette.primary.dark,
                  },
                })}
              >
                {!value ? <AddIcon /> : <EditIcon />}
              </IconButton>
            )}
          </>
        }
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Avatar
            sx={(theme) => ({
              width: theme.spacing(12),
              height: theme.spacing(12),
              backgroundColor: theme.palette.grey[700],
            })}
            src={value}
          />

          {loading && (
            <Box
              sx={(theme) => ({
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              })}
            >
              <CircularProgress
                size="16px"
                sx={{
                  color: "#fff",
                }}
              />
            </Box>
          )}
        </Box>
      </Badge>
    </>
  );
};

export default FilePicker;
