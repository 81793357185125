import differenceInYears from "date-fns/differenceInYears";
import format from "date-fns/format";
import take from "lodash/take";
import drop from "lodash/drop";

export const capitalizeFirstLetter = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

export const combineName = (firstName, surName) => {
  return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(
    surName
  )}`;
};

export const getAge = (dob) => {
  return differenceInYears(new Date(), new Date(dob));
};

export const sessionStatuses = {
  pending: "PENDING",
  accepted: "ACCEPTED",
  rejected: "REJECTED",
  completed: "COMPLETED",
};

export const formatDate = (date, dateFormat = "dd/LL/yyyy") => {
  return format(date, dateFormat);
};

export const paginate = (date, page, pageSize) => {
  const skip = (page - 1) * pageSize;

  return take(drop(date, skip), pageSize);
};

export const formatCurrency = (amount) => {
  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "GBP",
  });

  return numberFormatter.format(amount);
};
