import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import PageLoader from "../../components/pageLoader";
import useState from "../../hooks/useState";
import db from "../../firebase/config";
import Container from "../../components/container";
import Error from "../../components/error";
import Centered from "../../components/pageCentered/inddx";
import GuruProfile from "./components/guruProfile";

const Profile = () => {
  const [state, setState] = useState({
    guru: null,
    status: "idle",
    error: "",
  });

  const { guruId } = useParams();

  useEffect(() => {
    const fetchGuru = async () => {
      try {
        setState({
          status: "loading",
        });

        const docRef = doc(db, "gurus", guruId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          setState({
            status: "loaded",
            guru: {},
          });
        } else {
          setState({
            status: "loaded",
            guru: {
              id: docSnap.id,
              ...docSnap.data(),
            },
          });
        }
      } catch (err) {
        setState({
          status: "error",
          error: "Something went wrong.",
        });
      }
    };

    fetchGuru();
  }, [guruId]);

  const { status, guru, error } = state;

  let jsx = null;

  if (status === "loading") {
    jsx = <PageLoader />;
  } else if (status === "error") {
    jsx = (
      <Centered>
        <Error>{error}</Error>
      </Centered>
    );
  } else if (status === "loaded") {
    jsx = <GuruProfile guru={guru} />;
  }

  return (
    <Container maxWidth="lg" sx={{ padding: "30px" }}>
      {jsx}
    </Container>
  );
};

export default Profile;
