import dateFormat from "date-fns/format";

export const format = (date) => {
  return dateFormat(date, "P");
};

export const handleDatePickerError =
  (field, setFieldError) => (reason, value) => {
    console.log("reason: ", reason);
    switch (reason) {
      case "invalidDate":
        setFieldError(field, "Invalid date format");
        break;

      case "disablePast":
        setFieldError(field, "Values in the past are not allowed");
        break;

      case "maxDate":
      case "disableFuture":
        setFieldError(field, `Date should not be after ${format(new Date())}`);
        break;

      case "minDate":
        setFieldError(
          field,
          `Date should not be before ${format(new Date("01/01/1900"))}`
        );
        break;

      default:
        setFieldError(field, undefined);
        break;
    }
  };
