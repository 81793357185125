import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Link from "../../../components/link";
import Input from "../../../components/input";
import useAuth from "../../../providers/authProvider";
import PasswordField from "../../../components/passwordField";

const CustomPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 450,
  padding: theme.spacing(4),
}));

const SigninForm = () => {
  const { signin, sendEmailVerification, setStartTimer } = useAuth();

  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirect");

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email!")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const onSubmit = async ({ email, password }, { setSubmitting }) => {
    const res = await signin(email, password);
    if (res.status === "success") {
      if (!res.user.emailVerified) {
        toast.info(
          "It looks like you have not verified your email yet, please verify your email."
        );
        await sendEmailVerification(res.user);
        setStartTimer(true);
        navigate("/verify-email");
      } else if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        navigate("/");
      }
    } else if (res.status === "error") {
      toast.error(res.message);
      setSubmitting(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <CustomPaper>
      <Typography
        variant="h4"
        component="p"
        align="center"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightMedium,
        })}
      >
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <Input
          placeholder="Email"
          type="text"
          fullWidth
          margin="normal"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
          helperText={errors.email && touched.email ? errors.email : ""}
        />
        <PasswordField
          placeholder="Password"
          fullWidth
          margin="normal"
          name="password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.password && touched.password}
          helperText={
            errors.password && touched.password ? errors.password : ""
          }
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          sx={(theme) => ({
            marginTop: theme.spacing(3),
          })}
        >
          <FormControlLabel label="Remember me" control={<Checkbox />} />

          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting}
          >
            SIGN IN
          </LoadingButton>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={(theme) => ({
            marginTop: theme.spacing(3),
          })}
        >
          <Link to="/forgot-password">Forgot Password?</Link>
          <Typography variant="body2" align="right">
            Don't have an account?{" "}
            <Link
              to={
                redirectUrl ? `/register?redirect=${redirectUrl}` : "/register"
              }
            >
              Register
            </Link>
          </Typography>
        </Stack>
      </form>
    </CustomPaper>
  );
};

export default SigninForm;
