import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../providers/authProvider";

const ProtectedRoute = () => {
  const pathname = useLocation().pathname;

  const { user } = useAuth();

  let jsx = null;

  if (!user) {
    jsx = <Navigate to={`/signin?redirect=${pathname}`} />;
  } else if (!user.emailVerified) {
    jsx = <Navigate to="/verify-email" replace />;
  } else {
    jsx = <Outlet />;
  }
  return <>{jsx}</>;
};

export default ProtectedRoute;
