import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import LoadingButton from "@mui/lab/LoadingButton";
import useState from "../../hooks/useState";
import { combineName } from "../../utils";
import Input from "../input";

const BookSession = ({ guru, onCancel, onAccept, isLoading }) => {
  const [state, setState] = useState({
    time: new Date(),
    description: "",
  });

  const handleChange = (e) => {
    setState({
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <DialogTitle>
        Book session with {combineName(guru.firstName, guru.surname)}
      </DialogTitle>
      <DialogContent>
        <Typography>Select time</Typography>
        <DateTimePicker
          inputFormat="dd/MM/yyyy hh:mm a"
          value={state.time}
          onChange={(val) => {
            handleChange({
              target: {
                name: "time",
                value: val,
              },
            });
          }}
          renderInput={(params) => <Input {...params} fullWidth />}
          disablePast
        />
        <Box mt={3}>
          <Typography>Your message to the Guru</Typography>
          <Input
            rows={5}
            fullWidth
            multiline
            name="description"
            onChange={handleChange}
            value={state.description}
          />
        </Box>
        <DialogActions>
          <Button variant="contained" onClick={onCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            onClick={() =>
              onAccept({
                sessionTime: state.time,
                description: state.description,
              })
            }
            loading={isLoading}
          >
            Proceed
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default BookSession;
