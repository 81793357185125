import React, { useState } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import FilePicker from "../filePicker";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import {
  FormControlLabel,
  InputLabel,
  Stack,
  Checkbox,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { getDownloadURL } from "firebase/storage";
import GridItemLabel from "./gridItemLabel";
import GridItemInput from "./gridItemInput";
import Input from "../input";
import MultiSelect from "./multiSelect";
import DatePicker from "../datePicker";
import { handleDatePickerError } from "../utils";
import Select from "../select";
import { higherEducationOptions } from "./data/higherEducationOptions";
import { maritalStatusOptions } from "./data/maritalStatusOptions";
import { kidsOptions } from "./data/kidsOptions";
import { sexOptions } from "./data/sexOptions";
import { uploadUserAvatar } from "../../firebase/utils";
import useAuth from "../../providers/authProvider";
import insightsonOptions from "./data/insgihtsonOptions";

const InputGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[800],
  marginBottom: theme.spacing(2),
  display: "inline-block",
  position: "relative",

  "::after": {
    content: "''",
    display: "block",
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 2,
    backgroundColor: theme.palette.grey[700],
  },
}));

const GuruForm = ({ initialValues, handleSubmit: submit }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const [avatarIsLoading, setAvatarIsLoading] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.up("md"));

  const validationSchema = Yup.object().shape({
    avatar: Yup.string(),
    firstName: Yup.string()
      .required("First name is required.")
      .min(2, "First name is too short")
      .max(50, "First name is too long."),
    surname: Yup.string()
      .required("Surname is required.")
      .min(2, "Surname is too short.")
      .max(50, "Surname is too long"),
    dob: Yup.date().nullable(),
    sex: Yup.string(),
    location: Yup.string().required("Location is required."),
    maritalStatus: Yup.string(),
    kids: Yup.string(),
    languages: Yup.array()
      .required("Enter at least one language")
      .min(1, "Enter at least one language"),
    ethnicity: Yup.string(),

    highestQualifLevel: Yup.string().required(
      "Highest Qualification Level is required."
    ),
    university: Yup.string().required("University is required."),
    degree: Yup.string().required("Degree is required."),

    occupation: Yup.string().required("Occupation is required."),
    industry: Yup.string().required("Industry is required."),
    linkedinProfile: Yup.string(),

    interests: Yup.array()
      .required("Enter at least one interest")
      .min(1, "Enter at least one interest")
      .max(5, "Max 5 interests are allowed"),
    sports: Yup.array().max(5, "Max 5 sports are allowed"),

    tags: Yup.array()
      .required("Tags are required")
      .min(3, "Enter at least 3 tags")
      .max(8, "Max 8 tags are allowed"),

    insightson: Yup.array()
      .required("Insights on is required")
      .min(1, "Select at least one."),

    personalProfile: Yup.string()
      .required("Please add personal profile.")
      .min(200, "Personal profile must be at least 200 characters long.")
      .max(
        2024,
        "Personal profile must be leass then or equal to 2024 characters."
      ),

    price: Yup.number()
      .required("Price is required.")
      .min(30, "Should be at least 30£")
      .max(1000, "Should be maximum 1000£"),
  });

  const onSubmit = (values, formkiBag) => {
    submit(values, formkiBag);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      setAvatarIsLoading(true);

      const res = await uploadUserAvatar(file, user.uid);

      if (res.status === "success") {
        try {
          const avatarUrl = await getDownloadURL(res.data.ref);
          setFieldValue("avatar", avatarUrl);
        } catch (err) {}
      } else if (res.status === "error") {
        setFieldValue("avatar", "");
        toast.error(res.message);
      }

      setAvatarIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: "30px" }}>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center">
          <Grid item xs={12} md={2.25} lg={1.75}>
            <FilePicker
              onFileChange={handleAvatarChange}
              value={values.avatar}
              loading={avatarIsLoading}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={9.75}
            lg={10.25}
            sx={(theme) => ({
              marginTop: theme.spacing(3),
              [theme.breakpoints.up("md")]: {
                marginTop: 0,
              },
            })}
          ></Grid>
        </Grid>
        <Box>
          <Grid container spacing={isTablet ? 12 : 0}>
            {/* Left Side */}
            <Grid item xs={12} md={6}>
              <Box mt={6}>
                <SectionTitle variant="h5">Personal Details</SectionTitle>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="firstName">First Name*: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="firstName"
                      size="small"
                      fullWidth
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.firstName && touched.firstName}
                      helperText={
                        errors.firstName && touched.firstName
                          ? errors.firstName
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="surname">Surname*: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="surname"
                      size="small"
                      fullWidth
                      name="surname"
                      value={values.surname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.surname && touched.surname}
                      helperText={
                        errors.surname && touched.surname ? errors.surname : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="dob">DOB: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <DatePicker
                      inputFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        setFieldValue("dob", date, false);
                      }}
                      field="dob"
                      error={errors.dob}
                      touched={touched.dob}
                      setFieldTouched={setFieldTouched}
                      value={values.dob}
                      openTo="year"
                      views={["year", "month", "day"]}
                      disableFuture
                      maxDate={new Date()}
                      onError={(reason, value) => {
                        handleDatePickerError("dob", setFieldError)(
                          reason,
                          value
                        );
                      }}
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="sex">Sex: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Select
                      options={sexOptions}
                      value={values.sex}
                      name="sex"
                      onChange={(_, value) => {
                        setFieldValue("sex", value);
                      }}
                      disableClearable
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="location">Location*:</InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="location"
                      size="small"
                      fullWidth
                      name="location"
                      value={values.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.location && touched.location}
                      helperText={
                        errors.location && touched.location
                          ? errors.location
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="maritalStatus">
                      Marital Status:
                    </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Select
                      options={maritalStatusOptions}
                      value={values.maritalStatus}
                      name="maritalStatus"
                      onChange={(_, value) => {
                        setFieldValue("maritalStatus", value);
                      }}
                      disableClearable
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="kids">Kids: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Select
                      options={kidsOptions}
                      value={values.kids}
                      name="kids"
                      onChange={(_, value) => {
                        setFieldValue("kids", value);
                      }}
                      disableClearable
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="languages">Languages*: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <MultiSelect
                      id="languages"
                      data={values.languages}
                      handleDelete={(lang) => {
                        const filteredLanguages = values.languages.filter(
                          (language) => language !== lang
                        );
                        setFieldValue("languages", filteredLanguages);
                      }}
                      handleAdd={(language) =>
                        setFieldValue("languages", [
                          ...values.languages,
                          language,
                        ])
                      }
                      name="languages"
                      handleBlur={handleBlur}
                      error={Boolean(touched.languages && errors.languages)}
                      helperText={
                        touched.languages && errors.languages
                          ? errors.languages
                          : ""
                      }
                    />
                    {values.languages?.length <= 0 && (
                      <FormHelperText>
                        Enter one or more languages, press enter for adding
                        another language
                      </FormHelperText>
                    )}
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="ethnicity">Ethnicity: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="ethnicity"
                      size="small"
                      fullWidth
                      name="ethnicity"
                      value={values.ethnicity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.ethnicity && touched.ethnicity}
                      helperText={
                        errors.ethnicity && touched.ethnicity
                          ? errors.ethnicity
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>
              </Box>

              <Box mt={6}>
                <SectionTitle variant="h5">Education Details</SectionTitle>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="qualificationLevel">
                      Qualification*:
                    </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Select
                      options={higherEducationOptions}
                      value={values.highestQualifLevel}
                      name="highestQualifLevel"
                      onChange={(_, value) => {
                        setFieldValue("highestQualifLevel", value);
                      }}
                      disableClearable
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="university">University*: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="university"
                      size="small"
                      fullWidth
                      name="university"
                      value={values.university}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.university && touched.university}
                      helperText={
                        errors.university && touched.university
                          ? errors.university
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="degreeOrSubject">
                      Degree/Subject*:
                    </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="degreeOrSubject"
                      size="small"
                      fullWidth
                      name="degree"
                      value={values.degree}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.degree && touched.degree}
                      helperText={
                        errors.degree && touched.degree ? errors.degree : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="graudationYear">
                      Graduation Year:
                    </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <DatePicker
                      onChange={(year) => {
                        setFieldValue("graduationYear", year, false);
                      }}
                      field="graduationYear"
                      value={values.graduationYear}
                      error={errors.graduationYear}
                      touched={touched.graduationYear}
                      setFieldTouched={setFieldTouched}
                      openTo="year"
                      views={["year"]}
                      disableFuture
                      maxDate={new Date()}
                      onError={(reason, value) => {
                        handleDatePickerError("graduationYear", setFieldError)(
                          reason,
                          value
                        );
                      }}
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel>Currently Studying: </InputLabel>
                  </GridItemLabel>
                  <GridItemInput>
                    <Checkbox
                      checked={values.currentlyStudying}
                      onChange={(e) => {
                        setFieldValue("currentlyStudying", e.target.checked);
                      }}
                    />
                  </GridItemInput>
                </InputGrid>
              </Box>
            </Grid>

            {/* Right side */}
            <Grid item xs={12} md={6}>
              <Box mt={6}>
                <SectionTitle variant="h5">Professional Details</SectionTitle>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="occupation">Occupation:*</InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="occupation"
                      size="small"
                      fullWidth
                      name="occupation"
                      value={values.occupation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.occupation && touched.occupation}
                      helperText={
                        errors.occupation && touched.occupation
                          ? errors.occupation
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="industry">Industry:*</InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="industry"
                      size="small"
                      fullWidth
                      name="industry"
                      value={values.industry}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.industry && touched.industry}
                      helperText={
                        errors.industry && touched.industry
                          ? errors.industry
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="company">Company:</InputLabel>
                  </GridItemLabel>
                  <GridItemInput>
                    <Input
                      id="company"
                      size="small"
                      fullWidth
                      name="company"
                      value={values.company}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.company && touched.company}
                      helperText={
                        errors.company && touched.company ? errors.company : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="linkedInProfile">
                      LinkedIn Profile:
                    </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <Input
                      id="linkedInProfile"
                      size="small"
                      fullWidth
                      name="linkedinProfile"
                      value={values.linkedinProfile}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.linkedinProfile && touched.linkedinProfile}
                      helperText={
                        errors.linkedinProfile && touched.linkedinProfile
                          ? errors.linkedinProfile
                          : ""
                      }
                    />
                  </GridItemInput>
                </InputGrid>
              </Box>

              <Box mt={6}>
                <SectionTitle variant="h5">Lifestyle</SectionTitle>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="interests">Interests:*</InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <MultiSelect
                      id="interests"
                      data={values.interests}
                      handleDelete={(int) => {
                        const filteredInterests = values.interests.filter(
                          (interest) => interest !== int
                        );
                        setFieldValue("interests", filteredInterests);
                      }}
                      handleAdd={(interest) =>
                        setFieldValue("interests", [
                          ...values.interests,
                          interest,
                        ])
                      }
                      handleBlur={handleBlur}
                      name="interests"
                      error={Boolean(touched.interests && errors.interests)}
                      helperText={
                        touched.interests && errors.interests
                          ? errors.interests
                          : ""
                      }
                    />
                    {values.interests?.length <= 0 && (
                      <FormHelperText>
                        Enter one or more interests, press enter for adding
                        another interest
                      </FormHelperText>
                    )}
                  </GridItemInput>
                </InputGrid>

                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="sports">Sports:</InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <MultiSelect
                      id="sports"
                      data={values.sports}
                      handleDelete={(spo) => {
                        const filteredSports = values.sports.filter(
                          (sport) => sport !== spo
                        );
                        setFieldValue("sports", filteredSports);
                      }}
                      handleAdd={(sport) =>
                        setFieldValue("sports", [...values.sports, sport])
                      }
                      name="sports"
                      handleBlur={handleBlur}
                      error={Boolean(touched.sports && errors.sports)}
                      helperText={
                        touched.sports && errors.sports ? errors.sports : ""
                      }
                    />
                    {values.sports?.length <= 0 && (
                      <FormHelperText>
                        Enter one or more sports, press enter for adding another
                        hobby
                      </FormHelperText>
                    )}
                  </GridItemInput>
                </InputGrid>
              </Box>

              <Box mt={6}>
                <SectionTitle variant="h5">Tags</SectionTitle>
                <InputGrid container>
                  <GridItemLabel>
                    <InputLabel htmlFor="tags">Tags*: </InputLabel>
                  </GridItemLabel>

                  <GridItemInput>
                    <MultiSelect
                      id="tags"
                      data={values.tags}
                      handleDelete={(t) => {
                        const filteredTags = values.tags.filter(
                          (tag) => tag !== t
                        );
                        setFieldValue("tags", filteredTags);
                      }}
                      handleAdd={(tag) =>
                        setFieldValue("tags", [...values.tags, tag])
                      }
                      name="tags"
                      handleBlur={handleBlur}
                      error={Boolean(touched.tags && errors.tags)}
                      helperText={
                        touched.tags && errors.tags ? errors.tags : ""
                      }
                    />

                    <FormHelperText>
                      Tags will help you appear on searches
                    </FormHelperText>
                  </GridItemInput>
                </InputGrid>
              </Box>

              <Box mt={6}>
                <SectionTitle variant="h5">Personal Profile</SectionTitle>
                <InputGrid container>
                  <InputLabel>Can provide insights on:</InputLabel>
                  <Stack direction="row" mt={2}>
                    {insightsonOptions.map((op, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.insightson.includes(op)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                const newValues = [...values.insightson, op];
                                setFieldValue("insightson", newValues);
                              } else {
                                const newValues = values.insightson.filter(
                                  (s) => s !== op
                                );
                                setFieldValue("insightson", newValues);
                              }
                            }}
                          />
                        }
                        label={op}
                        key={index}
                      />
                    ))}
                  </Stack>
                  {touched.insightson && Boolean(errors.insightson) && (
                    <FormHelperText error>{errors.insightson}</FormHelperText>
                  )}
                </InputGrid>

                <InputGrid container>
                  <Input
                    minRows={10}
                    maxRows={10}
                    fullWidth
                    multiline
                    placeholder="<Summary of how you can be helpful to others, what insights you can provide, your interests, past experiences. Mention other countries you've lived, worked or studied in>"
                    name="personalProfile"
                    value={values.personalProfile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.personalProfile && touched.personalProfile}
                    helperText={
                      errors.personalProfile && touched.personalProfile
                        ? errors.personalProfile
                        : ""
                    }
                  />
                </InputGrid>

                <InputGrid container mt={2}>
                  <InputLabel
                    htmlFor="price"
                    sx={{
                      width: "max-content",
                    }}
                  >
                    Price per 45min session:
                  </InputLabel>
                  <Input
                    sx={{
                      marginTop: 0.7,
                    }}
                    type="number"
                    min={30}
                    max={100}
                    id="price"
                    size="small"
                    fullWidth
                    name="price"
                    value={values.price}
                    onChange={(e) =>
                      setFieldValue("price", Number(e.target.value))
                    }
                    onBlur={handleBlur}
                    error={errors.price && touched.price}
                    helperText={
                      errors.price && touched.price
                        ? errors.price
                        : "Guru will receive 70% of the price"
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                </InputGrid>
              </Box>
            </Grid>
          </Grid>
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              to="/dashboard"
              component={Link}
              sx={{
                marginRight: 1,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disableElevation
              disableFocusRipple
              disableTouchRipple
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Container>
  );
};

export default GuruForm;
