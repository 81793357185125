import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Value = styled(Typography)(({ theme }) => ({}));

const ItemValue = ({ children }) => {
  return (
    <Grid item xs={6} sm={8} md={6} lg={7} xl={8}>
      <Value>{children}</Value>
    </Grid>
  );
};

export default ItemValue;
