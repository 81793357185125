import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import sortBy from "lodash/sortBy";
import db from "../../firebase/config";
import useAuth from "../../providers/authProvider";
import Sessions from "./sessions";
import Profile from "./profile";
import Calendar from "./calendar";
import Requests from "./requests";
import { sessionStatuses, paginate } from "../../utils";
import useState from "../../hooks/useState";

const Main = styled("main")(({ theme }) => ({
  padding: theme.spacing(4),
}));

const SESSIONS_PAGE_SIZE = 2;

const Dashboard = () => {
  const [state, setState] = useState({
    expSessions: [],
    guruSessions: [],
    page: 1,
  });

  const { user, profile } = useAuth();

  useEffect(() => {
    let expSessionsUnsub;
    let guruSessionsUnsub;

    const expQuery = query(
      collection(db, "sessions"),
      where("explorerId", "==", user.uid),
      where("status", "==", sessionStatuses.accepted)
    );

    expSessionsUnsub = onSnapshot(expQuery, (querySnapshot) => {
      const sessions = [];

      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          sessions.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });

      setState({
        expSessions: sessions,
      });
    });

    if (profile) {
      const guruQuery = query(
        collection(db, "sessions"),
        where("guruId", "==", user.uid),
        where("status", "==", sessionStatuses.accepted)
      );

      guruSessionsUnsub = onSnapshot(guruQuery, (querySnapshot) => {
        const sessions = [];

        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            sessions.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });

        setState({
          guruSessions: sessions,
        });
      });
    }

    return () => {
      if (expSessionsUnsub) {
        expSessionsUnsub();
      }

      if (guruSessionsUnsub) {
        guruSessionsUnsub();
      }
    };
  }, [user, profile]);

  const sessionsUnsorted = [...state.expSessions, ...state.guruSessions];

  const allSessions = sortBy(sessionsUnsorted, (item) =>
    item.sessionTime.toDate()
  );

  const sessions = paginate(allSessions, state.page, SESSIONS_PAGE_SIZE);

  return (
    <>
      <Main>
        <Container>
          <Box mb={5}>
            <Profile user={user} profile={profile} />
          </Box>

          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Sessions
                sessions={sessions}
                pagesCount={Math.ceil(allSessions.length / SESSIONS_PAGE_SIZE)}
                handlePageChange={(newPage) => {
                  setState({
                    page: newPage,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Calendar sessions={allSessions} />
            </Grid>
          </Grid>

          <Box
            sx={(theme) => ({
              marginTop: theme.spacing(6),
            })}
          >
            <Requests />
          </Box>
        </Container>
      </Main>
    </>
  );
};

export default Dashboard;
