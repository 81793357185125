import React from "react";
import MuiGlobalStyles from "@mui/material/GlobalStyles";

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        html: {
          height: "100%",
        },

        body: {
          height: "100%",
        },

        "#root": {
          height: "100%",
        },
      }}
    />
  );
};

export default GlobalStyles;
