import React from "react";
import AutoComplete from "@mui/material/Autocomplete";
import Input from "../input";

const Select = (props) => {
  return (
    <AutoComplete
      {...props}
      renderInput={(params) => <Input {...params} size="small" />}
    />
  );
};

export default Select;
