import React from "react";
import { styled } from "@mui/material/styles";

const StyledFooter = styled("footer")(({ theme }) => ({
  padding: theme.spacing(5),
  backgroundColor: "#f7f7f7",
  display: "flex",
  justifyContent: "center",
}));

const ExternalLink = styled("a")(({ theme }) => ({
  ":link, :visited": {
    textDecoration: "none",
    color: theme.palette.grey[700],
    display: "inline-block",
    position: "relative",
  },

  "::before": {
    display: "block",
    content: '""',
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "2px",
    backgroundColor: theme.palette.grey[700],
    transform: "scaleX(0)",
    transformOrigin: "right",
    transition: "transform 250ms ease-in",
  },

  ":hover,:active": {
    "::before": {
      transformOrigin: "left",
      transform: "scaleX(100%)",
    },
  },
}));

const Footer = () => {
  return (
    <StyledFooter>
      <ExternalLink href="mailto:admin@nossela.com">Contact Us</ExternalLink>
    </StyledFooter>
  );
};

export default Footer;
