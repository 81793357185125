import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";

const StyledLink = styled(Link)(
  ({ theme }) => `
  text-decoration: none;
`
);

const StyledImage = styled("img")`
  height: 50px;
  display: flex;
`;

const Logo = ({ ...props }) => {
  return (
    <StyledLink {...props}>
      <StyledImage src={logo} alt="N" />
    </StyledLink>
  );
};

export default Logo;
