import React, { createContext, useState, useContext, useEffect } from "react";
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  sendEmailVerification as firebaseSendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
} from "firebase/auth";
import { onSnapshot, doc } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import db, { auth } from "../firebase/config";

const AuthContext = createContext(null);

AuthContext.displayName = "AuthContext";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [startTimer, setStartTimer] = useState(false);
  const [loading, setLoading] = useState(true);

  const signup = async (email, password, name) => {
    try {
      const userCredentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(userCredentials.user, {
        displayName: name,
      });

      return {
        status: "success",
        user: userCredentials.user,
      };
    } catch (err) {
      let message = "";

      switch (err.code) {
        case "auth/email-already-in-use":
          message =
            "The email entered is already in use. Please choose another email address";
          break;

        case "auth/weak-password":
          message = "Password must be at least 6 characters long";
          break;

        default:
          message =
            "Something went wrong while registering you. Please try again latter";
      }
      return {
        status: "error",
        message: message,
      };
    }
  };

  const signin = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      return {
        status: "success",
        user: userCredential.user,
      };
    } catch (err) {
      let message = "";

      switch (err.code) {
        case "auth/user-not-found":
          message = `User with email ${email} does not exist`;
          break;

        case "auth/wrong-password":
          message = "Invalid email or password";
          break;

        default:
          message = err.message;
      }

      return {
        status: "error",
        message,
      };
    }
  };

  const sendEmailVerification = async (user) => {
    try {
      await firebaseSendEmailVerification(user, {
        url: process.env.REACT_APP_DOMAIN,
      });

      return {
        status: "success",
      };
    } catch (err) {
      console.log("error: ", err);
      return {
        status: "error",
        message: err.message,
      };
    }
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      await firebaseSendPasswordResetEmail(auth, email, {
        url: `${process.env.REACT_APP_DOMAIN}/signin`,
      });
      return {
        status: "success",
        email,
      };
    } catch (err) {
      console.log(err);
      return {
        status: "error",
        message: err.message,
      };
    }
  };

  const logout = () => {
    setProfile(null);
    auth.signOut();
  };

  useEffect(() => {
    let unsubscribeProfile = null;

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      setUser(user);

      if (user) {
        unsubscribeProfile = onSnapshot(doc(db, "gurus", user.uid), (doc) => {
          if (doc.exists()) {
            setProfile({
              id: doc.id,
              ...doc.data(),
            });
          }
          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      if (unsubscribeProfile) {
        unsubscribeProfile();
      }
    };
  }, []);

  const value = {
    user,
    profile,
    signup,
    logout,
    startTimer,
    setStartTimer,
    sendEmailVerification,
    signin,
    sendPasswordResetEmail,
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <CircularProgress size="20px" />
        </Box>
      ) : (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      )}
    </>
  );
};

const useAuth = () => {
  const ctx = useContext(AuthContext);

  return ctx;
};

export default useAuth;
