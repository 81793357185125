import React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[600],
      transition: theme.transitions.create([
        "border-color",
        "background-color",
        "box-shadow",
      ]),
    },

    "&:hover fieldset": {
      borderColor: theme.palette.grey[900],
    },

    "&.Mui-focused fieldset": {
      borderColor: theme.palette.grey[900],
      boxShadow: `${theme.palette.grey[700]} 0 0 0 1px`,
      borderWidth: 1,
    },
  },
}));

const Input = (props) => {
  return <CustomTextField variant="outlined" {...props} />;
};

export default Input;
