import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const Head = styled("header")(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  padding: ${theme.spacing(8)} ${theme.spacing(2)};
  background-color: ${theme.palette.primary.light};
  @media only screen and (min-width: ${theme.breakpoints.values.md}px) {
    padding: ${theme.spacing(14)} ${theme.spacing(2)};
  }
`
);

const Hero = styled(Box)(
  ({ theme }) => `  
  max-width: ${theme.breakpoints.values.md}px;
  width: 100%;
`
);

const BodyText = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightRegular};
`
);

const Header = () => {
  return (
    <Head>
      <Hero>
        <Typography
          variant="h2"
          component="h1"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
          })}
        >
          Nossela
        </Typography>
        <BodyText variant="h4" component="h1" mt={1}>
          <span
            style={{
              fontStyle: "italic",
            }}
          >
            Connect. Learn. Decide.
          </span>
        </BodyText>
        <BodyText mt={4} variant="h6" component="h1">
          A global online network connecting you (the Explorer) to those who
          have already travelled the path (the Gurus) who can share valuable
          insights about "Living, Studying, or Working" in a new city!
        </BodyText>
        <BodyText mt={2} variant="h6" component="h1">
          Ask the questions you always wanted answered, before making the move!
        </BodyText>
        <Box mt={6}>
          <Button
            variant="contained"
            size="large"
            component={Link}
            to="/browse"
          >
            Find a Guru
          </Button>
        </Box>
      </Hero>
    </Head>
  );
};

export default Header;
