import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AppRoutes from "./components/routes";
import GlobalStyles from "./components/globalStyles";
import theme from "./utils/theme";

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </>
  );
};

export default App;
