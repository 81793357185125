import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { query, collection, where, onSnapshot } from "firebase/firestore";
import sortBy from "lodash/sortBy";
import reverse from "lodash/reverse";
import useAuth from "../../../providers/authProvider";
import useState from "../../../hooks/useState";
import db from "../../../firebase/config";
import Request from "./request";
import { sessionStatuses } from "../../../utils";

const Requests = () => {
  const [state, setState] = useState({
    expRequests: [],
    guruRequests: [],
  });

  const { user, profile } = useAuth();

  useEffect(() => {
    let expUnsub;
    let guruUnsub;

    const expQuery = query(
      collection(db, "sessions"),
      where("explorerId", "==", user.uid),
      where("status", "==", sessionStatuses.pending)
    );

    expUnsub = onSnapshot(expQuery, (querySnapshot) => {
      const requests = [];
      querySnapshot.forEach((doc) => {
        if (doc.exists()) {
          requests.push({
            id: doc.id,
            ...doc.data(),
          });
        }
      });
      setState({
        expRequests: requests,
      });
    });

    if (profile) {
      const guruQuery = query(
        collection(db, "sessions"),
        where("guruId", "==", user.uid),
        where("status", "==", sessionStatuses.pending)
      );

      guruUnsub = onSnapshot(guruQuery, (querySnapshot) => {
        const requests = [];
        querySnapshot.forEach((doc) => {
          if (doc.exists()) {
            requests.push({
              id: doc.id,
              ...doc.data(),
            });
          }
        });
        setState({
          guruRequests: requests,
        });
      });
    }

    return () => {
      if (expUnsub) expUnsub();

      if (guruUnsub) guruUnsub();
    };
  }, [user, profile]);

  const { expRequests, guruRequests } = state;

  const requestsUnsorted = [...expRequests, ...guruRequests];

  const requests = reverse(
    sortBy(requestsUnsorted, (item) => item.createdAt.toDate())
  );

  let requestsJsx = null;

  if (requests.length <= 0) {
    requestsJsx = (
      <Box
        sx={(theme) => ({
          padding: theme.spacing(4),
        })}
      >
        <Typography>Your requests will appear here</Typography>
      </Box>
    );
  } else {
    requestsJsx = (
      <>
        {requests.map((req) => (
          <Request key={req.id} req={req} />
        ))}
      </>
    );
  }

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[300]}`,
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={(theme) => ({
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          padding: theme.spacing(2),
        })}
      >
        <Typography
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightMedium,
          })}
        >
          REQUESTS
        </Typography>
      </Stack>

      {requestsJsx}
    </Box>
  );
};

export default Requests;
