import React from "react";
import MaterialDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import MenuLink from "../link";
import useAuth from "../../providers/authProvider";

const Drawer = ({ open, onClose, onLogout }) => {
  const { profile, user } = useAuth();
  return (
    <MaterialDrawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
      >
        <List>
          {profile && (
            <ListItem>
              <Avatar
                src={profile.avatar}
                alt={profile.firstName}
                component={Link}
                to={`/profile/${user.uid}`}
              />
            </ListItem>
          )}

          {!profile && (
            <ListItem>
              <MenuLink to="/become-guru">Become a Guru</MenuLink>
            </ListItem>
          )}

          <ListItem>
            <MenuLink to="/browse">Browse for Gurus</MenuLink>
          </ListItem>

          {!user && (
            <>
              <ListItem>
                <MenuLink to="/signin">Login</MenuLink>
              </ListItem>

              <ListItem>
                <MenuLink to="/register">Register</MenuLink>
              </ListItem>
            </>
          )}

          {user && (
            <>
              <ListItem>
                <MenuLink to="/dashboard">Dashboard</MenuLink>
              </ListItem>
              <ListItem onClick={onLogout}>Logout</ListItem>
            </>
          )}
        </List>
      </Box>
    </MaterialDrawer>
  );
};

export default Drawer;
