import React from "react";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import GuruForm from "../../components/guruForm";
import { higherEducationOptions } from "../../components/guruForm/data/higherEducationOptions";
import useAuth from "../../providers/authProvider";
import db from "../../firebase/config";
import { toast } from "react-toastify";

const BecomeGuru = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const initialValues = {
    avatar: "",
    firstName: "",
    surname: "",
    dob: null,
    sex: "",
    location: "",
    maritalStatus: "",
    kids: "",
    languages: [],
    ethnicity: "",

    highestQualifLevel:
      higherEducationOptions[higherEducationOptions.length - 1],
    university: "",
    degree: "",
    graduationYear: null,
    currentlyStudying: false,

    occupation: "",
    industry: "",
    linkedinProfile: "",
    company: "",

    interests: [],
    sports: [],

    tags: [],

    insightson: "",
    personalProfile: "",
    price: 30,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const guruRef = doc(db, "gurus", user.uid);

    const createdAt = Date.now();

    const data = {
      ...values,
      email: user.email,
      createdAt,
    };

    try {
      await setDoc(guruRef, data);

      toast.success("Guru profile is created sucessfully.");

      navigate("/");

      setSubmitting(false);
    } catch (err) {
      toast.error(
        err.message || "Something went wrong while creating your profile."
      );
      setSubmitting(false);
    }
  };

  return <GuruForm initialValues={initialValues} handleSubmit={onSubmit} />;
};

export default BecomeGuru;
