import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, getDoc } from "firebase/firestore";
import { storage } from "./config";
import db from "./config";

export const uploadUserAvatar = async (file, userId) => {
  const avatarRef = ref(storage, `avatars/${userId}.jpeg`);

  try {
    const snapshot = await uploadBytes(avatarRef, file);

    return {
      status: "success",
      data: snapshot,
    };
  } catch (err) {
    return {
      status: "error",
      message: err.reason,
    };
  }
};

export const getUserAvatar = async (userId) => {
  const avatarRef = ref(storage, `avatars/${userId}.jpeg`);

  try {
    const url = await getDownloadURL(avatarRef);

    return {
      status: "success",
      data: url,
    };
  } catch (err) {
    return {
      status: "error",
      message: err.message,
    };
  }
};

export const getGuruProfile = async (userId) => {
  try {
    const docRef = doc(db, "gurus", userId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        status: "success",
        data: {
          id: docSnap.id,
          ...docSnap.data(),
        },
      };
    }

    return {
      status: "success",
      data: null,
    };
  } catch (err) {
    return {
      status: "error",
      message: err.message,
    };
  }
};
