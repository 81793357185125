import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyA6EnODT2FbNXYxT39gXcee-GYamT7R1f8",
  authDomain: "nossela-62376.firebaseapp.com",
  projectId: "nossela-62376",
  storageBucket: "nossela-62376.appspot.com",
  messagingSenderId: "787817190440",
  appId: "1:787817190440:web:6a3dc42073803870a2511d",
};

const app = initializeApp(firebaseConfig);

let fAuth;
if (process.env.NODE_ENV === "development") {
  fAuth = getAuth();
  connectAuthEmulator(fAuth, "http://localhost:9099");
} else {
  fAuth = getAuth(app);
}

export const auth = fAuth;

let fDb;

if (process.env.NODE_ENV === "development") {
  fDb = getFirestore();
  connectFirestoreEmulator(fDb, "localhost", 9892);
} else {
  fDb = getFirestore(app);
}

const db = fDb;

if (process.env.NODE_ENV === "development") {
  const functions = getFunctions(app);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export const storage = getStorage(app);

export default db;
