import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import NotFoundImage from "../../assets/images/404.svg";
import React from "react";

const NotFound = () => {
  return (
    <Container>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <img src={NotFoundImage} alt="" />
        <Typography
          variant="h2"
          component="p"
          sx={(theme) => ({
            marginTop: theme.spacing(8),
            fontWeight: theme.typography.fontWeightMedium,
            textTransform: "uppercase",
          })}
        >
          Opps page not found!
        </Typography>
        <Typography variant="h6" component="p">
          Sorry, the page you are looking for doesn't exist.
        </Typography>

        <Box>
          <Button component={Link} to="/">
            Return Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
