import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../providers/authProvider";

const NotLoggedIn = () => {
  const { user } = useAuth();

  if (!user) return <Outlet />;

  return <Navigate to="/dashboard" replace />;
};

export default NotLoggedIn;
