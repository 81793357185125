import React from "react";
import Box from "@mui/material/Box";
import RegisterForm from "./components/registerForm";

const Register = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: theme.spacing(4),
        minHeight: "inherit",
      })}
    >
      <RegisterForm />
    </Box>
  );
};

export default Register;
