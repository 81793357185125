import React from "react";
import Grid from "@mui/material/Grid";

const GridItemLabel = ({ children }) => {
  return (
    <Grid item xs={12} sm={4} md={5} lg={4}>
      {children}
    </Grid>
  );
};

export default GridItemLabel;
