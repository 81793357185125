import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Link from "../../../components/link";
import useAuth from "../../../providers/authProvider";
import { formatCurrency, formatDate } from "../../../utils";
import { Box, Typography } from "@mui/material";

const Row = ({ session }) => {
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const isExplorer = session.explorerId === user.uid;

  let price = session.guru.price || 0;

  if (!isExplorer) price = (70 / 100) * price;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link to={isExplorer ? `/profile/${session.guruId}` : "#"}>
            {isExplorer ? session.guru.name : session.explorer.name}
          </Link>
        </TableCell>
        <TableCell>{isExplorer ? "Guru" : "Explorer"}</TableCell>
        <TableCell>{price ? formatCurrency(price) : "--"}</TableCell>
        <TableCell>
          {session.sessionTime
            ? formatDate(session.sessionTime.toDate(), "dd/LLL/yyyy HH:mm")
            : "--"}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Description
              </Typography>
              <Typography variant="body2">{session.description}</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const ResultTable = ({ sessions }) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Date/Time</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sessions.map((session) => (
              <Row session={session} key={session.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ResultTable;
