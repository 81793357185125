import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../../providers/authProvider";

const NotHaveProfile = () => {
  const { profile } = useAuth();

  if (!profile) return <Outlet />;

  return <Navigate to="/update-profile" replace />;
};

export default NotHaveProfile;
