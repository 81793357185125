import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import useAuth from "../../providers/authProvider";

const HaveProfile = () => {
  const { profile } = useAuth();

  if (profile) return <Outlet />;

  return <Navigate to="/become-guru" replace />;
};

export default HaveProfile;
