import React from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "../container";
import AppBar from "../appBar";
import Footer from "../footer";

const Root = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
}));

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: `${theme.spacing(4)} 0`,
}));

const Layout1 = () => {
  return (
    <Root>
      <AppBar />
      <Main>
        <Container>
          <Outlet />
        </Container>
      </Main>
      <Footer />
    </Root>
  );
};

export default Layout1;
