import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import sortBy from "lodash/sortBy";
import Error from "../../../components/error";
import Centered from "../../../components/pageCentered/inddx";
import useState from "../../../hooks/useState";
import ResultTable from "../resultTable";
import db from "../../../firebase/config";
import { sessionStatuses } from "../../../utils";
import useAuth from "../../../providers/authProvider";

const RejectedSessions = () => {
  const { user } = useAuth();
  const [state, setState] = useState({
    status: "idle",
    sessions: [],
  });

  useEffect(() => {
    setState((old) => ({
      ...old,
      status: "loading",
    }));

    const fetchSessions = async () => {
      try {
        // Rejected Sessions where loggedin user is guru
        const rejectedGuruSessionsQuery = query(
          collection(db, "sessions"),
          where("status", "==", sessionStatuses.rejected),
          where("guruId", "==", user.uid)
        );

        // Rejected sessions where loggedin user is explorer
        const rejectedExplorerSessionsQuery = query(
          collection(db, "sessions"),
          where("status", "==", sessionStatuses.rejected),
          where("explorerId", "==", user.uid)
        );

        const rejectedGuruSessionsSanpshot = await getDocs(
          rejectedGuruSessionsQuery
        );

        const rejectedExplorerSessionsSnapshot = await getDocs(
          rejectedExplorerSessionsQuery
        );

        const sessions = [];

        rejectedGuruSessionsSanpshot.forEach((doc) => {
          sessions.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        rejectedExplorerSessionsSnapshot.forEach((doc) => {
          sessions.push({
            id: doc.id,
            ...doc.data(),
          });
        });

        const sortedSessions = sortBy(sessions, (s) =>
          s.createdAt.toDate()
        ).reverse();

        setState({
          status: "loaded",
          sessions: sortedSessions,
        });
      } catch (err) {
        setState((old) => ({
          ...old,
          status: "error",
        }));
      }
    };

    fetchSessions();
  }, [user]);

  const { status, sessions } = state;

  let jsx;

  if (status === "loading")
    jsx = (
      <Centered>
        <CircularProgress size={16} />
      </Centered>
    );
  else if (status === "error") jsx = <Error>Something went wrong</Error>;
  else if (status === "loaded") jsx = <ResultTable sessions={sessions} />;

  return <>{jsx}</>;
};

export default RejectedSessions;
