import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { Navigate } from "react-router-dom";
import Input from "../../components/input";
import useAuth from "../../providers/authProvider";

const ForgotPassword = () => {
  const { user, sendPasswordResetEmail } = useAuth();
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(0);
  const [email, setEmail] = useState("");

  useEffect(() => {
    let interval;

    if (time !== 0) {
      interval = setTimeout(() => {
        setTime((oldTime) => oldTime - 1);
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [time]);

  const sendEmail = async () => {
    setLoading(true);

    const res = await sendPasswordResetEmail(email);

    if (res.status === "success") {
      toast.success(`Email is sent to ${res.email}`);
      setTime(60);
    } else {
      toast.error(res.message);
    }

    setLoading(false);
  };

  if (user) return <Navigate to="/dashboard" />;

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box maxWidth="500px">
        <Typography
          mt={4}
          variant="h4"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
          })}
        >
          Reset Your Password
        </Typography>

        <Typography mt={1}>
          Enter an email address associated with your account and we'll send you
          instructions to reset your password.
        </Typography>

        <Box mt={4} mb={2}>
          <Input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            placeholder="Enter your email..."
          />
        </Box>

        <LoadingButton
          disabled={time !== 0}
          variant="contained"
          loading={loading}
          onClick={sendEmail}
        >
          {time !== 0 ? `Resend in ${time}s` : "Reset Password"}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ForgotPassword;
