import { useState as reactUseState } from "react";

const useState = (initialState) => {
  const [state, reactSetState] = reactUseState(initialState);

  const setState = (newState) => {
    return reactSetState((oldState) => ({
      ...oldState,
      ...newState,
    }));
  };

  return [state, setState];
};

export default useState;
