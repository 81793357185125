import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const Label = styled(Typography)(({ theme }) => ({}));

const ItemLabel = ({ children }) => {
  return (
    <Grid item xs={6} sm={4} md={6} lg={5} xl={4}>
      <Label>{children}</Label>
    </Grid>
  );
};

export default ItemLabel;
