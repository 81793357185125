import React from "react";
import {
  doc,
  getDocs,
  writeBatch,
  query,
  where,
  collection,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import db from "../../firebase/config";
import useAuth from "../../providers/authProvider";
import GuruForm from "../../components/guruForm";
import { combineName } from "../../utils";
import insightsonOptions from "../../components/guruForm/data/insgihtsonOptions";

const UpdateGuruProfile = () => {
  const navigate = useNavigate();
  const { profile, user } = useAuth();

  const initialValues = {
    avatar: profile.avatar,
    firstName: profile.firstName,
    surname: profile.surname,
    dob: profile.dob?.toDate() ?? null,
    sex: profile.sex ?? "",
    location: profile.location,
    maritalStatus: profile.maritalStatus,
    kids: profile.kids,
    languages: [...profile.languages],
    ethnicity: profile.ethnicity,

    highestQualifLevel: profile.highestQualifLevel,
    university: profile.university,
    degree: profile.degree,
    graduationYear: profile.graduationYear?.toDate() ?? null,
    currentlyStudying: profile.currentlyStudying ?? false,

    occupation: profile.occupation,
    industry: profile.industry,
    linkedinProfile: profile.linkedinProfile ?? "",
    company: profile.company ?? "",

    interests: [...profile.interests],
    sports: profile.sports ? [...profile.sports] : [],

    tags: [...profile.tags],

    personalProfile: profile.personalProfile ?? "",
    insightson: profile.insightson ?? [insightsonOptions[0]],
    price: profile.price ?? 30,
  };

  const onSubmit = async (values, { setSubmitting }) => {
    const batch = writeBatch(db);

    const guruRef = doc(db, "gurus", user.uid);
    const sessionsRef = collection(db, "sessions");

    let guruSessionsQuery;
    let explorerSessionQuery;

    if (profile) {
      guruSessionsQuery = query(sessionsRef, where("guruId", "==", user.uid));
    }

    if (user) {
      explorerSessionQuery = query(
        sessionsRef,
        where("explorerId", "==", user.uid)
      );
    }

    const updatedAt = Date.now();

    const data = {
      ...values,
      updatedAt,
    };

    try {
      batch.update(guruRef, data);

      const name = combineName(values.firstName, values.surname);
      const avatar = values.avatar;
      const location = values.location;

      if (
        name !== combineName(profile.firstName, profile.surname) ||
        avatar !== profile.avatar ||
        location !== profile.location
      ) {
        if (guruSessionsQuery) {
          const guruSessionsSnapshot = await getDocs(guruSessionsQuery);

          guruSessionsSnapshot.forEach((doc) => {
            batch.update(doc.ref, {
              "guru.name": combineName(values.firstName, values.surname),
              "guru.avatar": values.avatar,
              "guru.location": values.location,
            });
          });
        }

        if (explorerSessionQuery) {
          const explorerSessionsSnapshot = await getDocs(explorerSessionQuery);

          explorerSessionsSnapshot.forEach((doc) => {
            batch.update(doc.ref, {
              "explorer.name": combineName(values.firstName, values.surname),
              "explorer.avatar": values.avatar,
              "explorer.location": values.location,
            });
          });
        }
      }

      await batch.commit();

      toast.success("Your profile is updated.");

      setSubmitting(false);

      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      toast.error(
        err.message || "Something went wrong while updating your profile."
      );
      setSubmitting(false);
    }
  };

  return <GuruForm initialValues={initialValues} handleSubmit={onSubmit} />;
};

export default UpdateGuruProfile;
