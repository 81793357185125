import { styled } from "@mui/material/styles";
import { Link as RouteLink } from "react-router-dom";

const Link = styled(RouteLink)(
  ({ theme }) => `
  :link,
  :visited {
    text-decoration: none;
    color: ${theme.palette.grey[700]};
    display: inline-block;
    position: relative;
  }

  ::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: ${theme.palette.grey[700]};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 250ms ease-in;
  }

  :hover,
  :active {
    ::before {
      transform-origin: left;
      transform: scaleX(100%);
    }
  }
`
);

export default Link;
