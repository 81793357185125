import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5DE3B0",
      light: "#008F8C",
      dark: "#015958",
      contrastText: "#333",
    },

    secondary: {
      main: "#F2E3D5",
      contrastText: "#333",
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

export default theme;
