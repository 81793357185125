import React from "react";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import AppLink from "../../../../components/link";
import { combineName, formatCurrency, getAge } from "../../../../utils/index";
import BookSessionModal from "../../../../components/bookSessionModal";
import useState from "../../../../hooks/useState";
import useAuth from "../../../../providers/authProvider";

const ChipItem = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  marginBottom: theme.spacing(0.5),

  "&:not(:last-child)": {
    marginRight: theme.spacing(0.5),
  },
}));

const Item = ({ guru }) => {
  const { user } = useAuth();
  const [state, setState] = useState({
    openBookSessionDialog: false,
    selcetedGuru: {},
  });
  const navigate = useNavigate();

  const onBokkSession = () => {
    if (!user) {
      navigate("/signin?redirect=/browse");
    } else {
      setState({
        openBookSessionDialog: true,
        selcetedGuru: guru,
      });
    }
  };

  return (
    <>
      <BookSessionModal
        open={state.openBookSessionDialog}
        guru={state.selcetedGuru}
        onClose={() => {
          setState({
            openBookSessionDialog: false,
          });
        }}
      />
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
          },
        })}
      >
        <Avatar
          sx={(theme) => ({
            width: theme.spacing(16),
            height: theme.spacing(16),
          })}
          variant="square"
          src={guru.avatar}
        />

        <Box
          sx={(theme) => ({
            marginTop: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              marginTop: 0,
              marginLeft: theme.spacing(3),
            },
          })}
        >
          <Typography
            variant="h6"
            sx={(theme) => ({
              fontWeight: theme.typography.fontWeightMedium,
            })}
          >
            <AppLink to={`/profile/${guru.id}`}>
              {combineName(guru.firstName, guru.surname)}
            </AppLink>
          </Typography>

          <List dense={true} disablePadding>
            <ListItem disablePadding>{guru.location}</ListItem>
            <ListItem disablePadding>
              {guru.dob && getAge(new Date(guru.dob))}, {guru.maritalStatus}
            </ListItem>
            <ListItem disablePadding>{guru.occupation}</ListItem>
            <ListItem disablePadding>{guru.languages?.join(", ")}</ListItem>
          </List>
        </Box>
      </Box>

      <Typography
        sx={(theme) => ({
          marginTop: theme.spacing(2),

          [theme.breakpoints.up("sm")]: {
            marginTop: theme.spacing(3),
          },
        })}
      >
        {guru.personalProfile}
      </Typography>

      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: theme.spacing(2),

          [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            marginTop: theme.spacing(3),
          },
        })}
      >
        <Stack direction="row" flexGrow={1} flexWrap="wrap">
          {guru.tags?.map((tag) => (
            <ChipItem key={tag}>
              <Chip
                clickable
                component={Link}
                to={`/browse?tag=${tag}`}
                label={tag}
                variant="outlined"
              />
            </ChipItem>
          ))}
        </Stack>
        {guru.price && (
          <Stack
            direction="row"
            alignItems="center"
            alignSelf="flex-end"
            sx={(theme) => ({
              marginTop: theme.spacing(2),

              [theme.breakpoints.up("md")]: {
                marginTop: 0,
              },
            })}
          >
            <Typography
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightBold,
                marginRight: theme.spacing(2),
              })}
            >
              {formatCurrency(guru.price)} per session
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={onBokkSession}
              disabled={user?.uid === guru.id}
            >
              Book Session
            </Button>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default Item;
