import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import Input from "../input";

const Searchbar = ({ clear, ...props }) => {
  return (
    <Input
      fullWidth
      size="small"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              disabled={props.value.trim().length <= 0}
              onClick={clear}
            >
              {props.value.trim().length <= 0 ? <SearchIcon /> : <ClearIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default Searchbar;
