import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MaterialAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Container from "../container";
import Logo from "../logo";
import MenuLink from "../link";
import useAuth from "../../providers/authProvider";
import Drawer from "./drawer";

const Header = styled("header")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const StyledAppBar = styled(MaterialAppBar)(
  ({ theme }) => `
  background-color: ${theme.palette.grey[50]};
  box-shadow: ${theme.shadows[0]}
`
);

const LeftMenu = styled("ul")(
  ({ theme }) => `
  display: flex;
  align-items: center;
  list-style-type: none;

  li:not(:last-child) {
    margin-right: ${theme.spacing(6)}
  }
`
);

const RightMenu = styled(Box)(
  ({ theme }) => `
  margin-left: auto;
  display: flex;
  align-items: center;
  list-style-type: none;

  li:not(:last-child) {
    margin-right: ${theme.spacing(6)}
  }
`
);

const MenuItem = styled("li")(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const Navbar = () => {
  const navigate = useNavigate();
  const { user, logout, profile } = useAuth();

  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleLogout = async () => {
    await logout();
    navigate("/signin");
  };

  return (
    <Header>
      <StyledAppBar position="static">
        <Container>
          <Toolbar>
            <Box>
              <Logo to="/">N</Logo>
            </Box>
            {/* Display on tablet and desktop */}
            {!isMobile && (
              <>
                <LeftMenu>
                  {!profile && (
                    <MenuItem>
                      <MenuLink to="/become-guru">Become a Guru</MenuLink>
                    </MenuItem>
                  )}
                  <MenuItem>
                    <MenuLink to="/browse">Browse for Gurus</MenuLink>
                  </MenuItem>
                </LeftMenu>

                <RightMenu>
                  {!user && (
                    <>
                      <MenuItem>
                        <MenuLink to="/signin">Login</MenuLink>
                      </MenuItem>
                      <MenuItem>
                        <MenuLink to="/register">Register</MenuLink>
                      </MenuItem>
                    </>
                  )}

                  {user && (
                    <>
                      <MenuItem>
                        <MenuLink to="/dashboard">Dashboard</MenuLink>
                      </MenuItem>
                      <MenuItem>
                        <Button color="inherit" onClick={handleLogout}>
                          Logout
                        </Button>
                      </MenuItem>
                    </>
                  )}

                  {profile && (
                    <MenuItem>
                      <Avatar
                        src={profile.avatar}
                        alt={profile.firstName}
                        component={Link}
                        to={`/profile/${user.uid}`}
                      />
                    </MenuItem>
                  )}
                </RightMenu>
              </>
            )}

            {/* Display on mobile */}
            {isMobile && (
              <IconButton
                size="medium"
                sx={{ marginLeft: "auto" }}
                onClick={() => setIsOpen(true)}
              >
                <MenuIcon
                  sx={{
                    width: "1.4em",
                    height: "1.4em",
                  }}
                />
              </IconButton>
            )}
          </Toolbar>
        </Container>
      </StyledAppBar>

      {/* Render on mobile only */}
      {isMobile && (
        <Drawer
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onLogout={handleLogout}
        />
      )}
    </Header>
  );
};

export default Navbar;
