import React from "react";
import MuiContainer from "@mui/material/Container";

const Container = ({ children }) => (
  <MuiContainer maxWidth="lg" sx={{ width: "100%", height: "100%" }}>
    {children}
  </MuiContainer>
);

export default Container;
