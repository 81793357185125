import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import isSameDay from "date-fns/isSameDay";
import Box from "@mui/material/Box";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSame",
})(({ theme, isSame }) => ({
  borderRadius: 10,

  "&.Mui-disabled": {
    color: theme.palette.grey[700],
  },

  ...(isSame && {
    backgroundColor: theme.palette.primary.main,
    "&.Mui-disabled": {
      color: theme.palette.primary.contrastText,
    },
  }),
}));

const Calendar = ({ sessions }) => {
  const renderDay = (date, selectedDates, pickersDayProps) => {
    let isSame = false;

    sessions.forEach((session) => {
      if (isSameDay(date, session.sessionTime.toDate())) isSame = true;
    });

    return <CustomPickersDay {...pickersDayProps} isSame={isSame} />;
  };

  const calendarCellWidth = "14.28%";
  const rowHeight = "14.28%";

  return (
    <Box
      sx={{
        height: "100%",

        "& .MuiPickerStaticWrapper-root": {
          height: "100%",
        },

        "& .MuiPickerStaticWrapper-content": {
          height: "100%",
        },

        "& .css-j7qwjs": {
          height: "100%",
        },

        "& .css-epd502": {
          margin: 0,
          width: "100%",
          maxHeight: "100%",
          height: "100%",
        },

        "& .MuiCalendarPicker-root": {
          margin: 0,
          width: "100%",
          maxHeight: "100%",
          height: "100%",
        },

        "& .MuiCalendarPicker-viewTransitionContainer": {
          height: "calc(100% - 30px)",
        },

        "& .MuiCalendarPicker-viewTransitionContainer > div": {
          height: "100%",
        },

        "& .css-1n2mv2k": {
          height: rowHeight,
        },

        "& .css-1w13o7u-MuiTypography-root": {
          width: calendarCellWidth,
        },

        "& .css-dhopo2": {
          height: `calc(100% - ${rowHeight})`,
        },

        "& .css-i6bazn": {
          height: "100%",
        },

        "& .css-mvmu1r": {
          height: "16.7%",
          alignItems: "center",
        },

        "& .MuiPickersDay-root": {
          width: calendarCellWidth,
          height: "100%",
        },
      }}
    >
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        disableHighlightToday={true}
        renderDay={renderDay}
        onChange={() => {}}
        value={null}
        readOnly={true}
        shouldDisableDate={() => true}
        fullWidth
        renderInput={(params) => <TextField {...params} />}
      />
    </Box>
  );
};

export default Calendar;
